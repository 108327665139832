import {Container, IInit} from '../../../common/container/Container'
import {Observable} from 'rxjs'
import {Embryo, EmbryoDTO, EmbryoQuery, EmbryosGroup} from '../models/Embryo'
import {ItemList} from '../../../common/models/ItemList'
import {Query} from '../../../common/api/Query'
import {IEmbryoApi} from '../api/EmbryoApi'
import {IStatusService} from '../../../common/status/StatusService'
import {STATUS_SERVICE_KEY} from '../../../container/app'
import { EmbryoGender } from 'modules/tests/models/Reports'


type Props = {
    apiKey: symbol
}

export interface IEmbryoService extends IInit {
    getByID(id: string): Observable<Embryo | undefined>

    getFilteredList(q: Query<EmbryoQuery>): Observable<ItemList<EmbryoDTO>>

    getEmbryosGroup(q: Query<EmbryosGroup>): Observable<ItemList<EmbryosGroup>>

    add(e: Embryo): Observable<any | undefined>

    update(e: EmbryoDTO): Observable<Embryo | undefined>

    updateFile(e: EmbryoDTO): Observable<Embryo | undefined>

    delete(id: string): Observable<boolean>

    runEmbryoSnipetsProcess(projectID: string): Observable<boolean>

    updateEmbryoGroup(e: EmbryosGroup): Observable<EmbryosGroup | undefined>

    updateBamFiles(e: EmbryoDTO): Observable<Embryo | undefined>
}

export class EmbryoService implements IEmbryoService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: IEmbryoApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<IEmbryoApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    add(e: EmbryoDTO): Observable<any | undefined> {
        return this._api.add(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    getByID(id: string): Observable<Embryo | undefined> {
        return this._api.getByID(id)
    }

    getFilteredList(q: Query<EmbryoQuery>): Observable<ItemList<EmbryoDTO>> {
        return this._api.getFilteredList(q)
    }

    getEmbryosGroup(q: Query<EmbryosGroup>): Observable<ItemList<EmbryosGroup>> {
        return this._api.getEmbryosGroup(q)
    }

    update(e: EmbryoDTO): Observable<Embryo | undefined> {
        return this._api.update(e)
    }

    updateFile(e: EmbryoDTO): Observable<Embryo | undefined> {
        return this._api.updateFile(e)
    }

    updateEmbryoGroup(e: EmbryosGroup): Observable<EmbryosGroup | undefined> {
        return this._api.updateEmbryoGroup(e)
    }

    runEmbryoSnipetsProcess(projectID: string): Observable<boolean> {
        return this._api.runEmbryoSnipetsProcess(projectID)
    }

    updateBamFiles(e: EmbryoDTO): Observable<Embryo | undefined>{
        return this._api.updateBamFiles(e)
    }
}
