import {Box, Button, FormControl, Grid} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {FileType} from './Form'
import {COLOR_CLINICS, COLOR_PRIMARY} from 'routes/color-constants'
import {Project, ProjectAnalysis, ProjectDTO, ProjectQuery} from 'modules/projects/models/Project'
import {Actions, Field, Pager, Search, SearchValue, Sort} from 'components/table/types'
import {AppTable} from 'components/table/AppTable'
import React, {useEffect, useState} from 'react'
import {BoolQueryParam, Query, QueryParam, QueryParamN, SortParam} from 'common/api/Query'
import {getProjectContainer} from 'container/project-module'
import {ProjectService} from 'modules/projects/services/ProjectService'
import {PROJECT_SERVICE_KEY} from 'modules/projects'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import seeIcon from '../../assets/table-icons/see-icon.svg'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import {downloadFile} from 'common/files/file'
import {navigate} from '@reach/router'
import {URL_PROYECTPGSEC, URL_SHOW_PROYECT} from 'routes/routes-constants'
import {getAuthContainer} from "../../container/auth-modules";
import {AuthService} from "../../modules/auth/services/AuthService";
import {AUTH_SERVICE_KEY} from "../../modules/auth";
import {useStyles} from "../patients/Detail.styles";
import {Autocomplete, Modal} from "@mui/material";
import {commonStyles} from "../../common/styles/Styles";
import TextField from "@material-ui/core/TextField";
import {FormAction, FormActions} from "../../common/utils/form-generation";
import {getPatientContainer} from "../../container/patient-module";
import {PatientService} from "../../modules/patients/services/PatientService";
import { FileService } from '../../modules/files/services/FileService'
import {PATIENT_SERVICE_KEY} from "../../modules/patients";
import { getFileContainer } from 'container/file-module'
import { FILE_SERVICE_KEY } from 'modules/files'
import { PatientQuery } from 'modules/patients/models/PatientPGD'



type FirstStepProps = {
    increaseStep: () => void
    project?: ProjectDTO
    files: (ids: FileType[]) => void
    handleSelectProject: (p: ProjectAnalysis) => void
}

const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)


const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)


const searcherQuery = (
    svs: SearchValue<ProjectQuery>[],
): QueryParam<ProjectQuery>[] | QueryParamN<ProjectQuery>[] =>
    svs.filter((sv) => sv.value).map((sv) => ({name: sv.name, value: sv.value as string}))


const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const FirstStep = (props: FirstStepProps) => {
    const {t} = useTranslation()
    const loggedUser = authService.get()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [pager, setPager] = useState<Pager>()
    const [page, setPage] = useState<number>(0)
    const [count, setCount] = useState<number>(0)
    const [itemsPerPage, setItemsPerPage] = useState<number>(10)
    const [items, setItems] = useState<ProjectAnalysis[]>([])

    const addItems = (newItems: ProjectAnalysis) => {
        setItems((prevItems) => [...prevItems, newItems])
    };
    const [selectedProject, setSelectedProject] = useState<ProjectDTO | undefined>(props.project)
    const [sort, setSort] = useState<SortParam<Project>>({
        field: 'createdAt',
        desc: true,
    })
    const classes = useStyles({color: COLOR_CLINICS})
    const styles2 = commonStyles()


    const [searcher, setSearcher] = useState<SearchValue<ProjectQuery>[]>([
        {
            name: 'name',
            label: loggedUser.language === 'es' ? t('search') + ' ' + t('byName') : 'Search by Name',
            value: localStorage.getItem('search_name') || '',
        },
        {
            name: 'nameHer',
            label: loggedUser.language === 'es' ? t('search') + ' ' + t('byHerName') : 'Search by her name',
            value: localStorage.getItem('search_nameHer') || '',
        },

        {
            name: 'nameHim',
            label: loggedUser.language === 'es' ? t('search') + ' ' + t('byHisName') : 'Search by his name',
            value: localStorage.getItem('search_nameHim') || '',
        },
        {
            name: 'infoAnalizedBy',
            label: loggedUser.language === 'es' ? t('search') + ' ' + t('searchAnalize') : 'Search by analized',
            value: localStorage.getItem('search_analizedBy') || '',
        },
        {
            name: 'infoValidateBy',
            label: loggedUser.language === 'es' ? t('search') + ' ' + t('searchValidate') : 'Search by validated',
            value: localStorage.getItem('search_validateBy') || '',
        },
    ])

    useEffect(() => {
        // Almacenar valores de búsqueda en el localStorage
        searcher.forEach(sv => {
            localStorage.setItem(`search_${sv.name}`, sv.value || '');
        });
    }, [searcher]);


    useEffect(() => {
          
        setItems([])
        if (!isLoading) return;
        setIsLoading(true)
         let name: string = ""
         const query: (QueryParamN<ProjectQuery>)[] = [
            ...searcherQuery(searcher),
            {
                name: 'pgType',                        
                value: "pgsec",  
            }
        ];
        projectService.getFilteredListAnalysis(
            new Query({
                pager: {offset: page * itemsPerPage, limit: itemsPerPage},
                query,
                sort: [{field: sort.field, desc: sort.desc}],
            }),
        ).subscribe((res) => {
               setIsLoading(false)
                setCount(res.count)
                for (let i = 0; i < res.items.length; i++) {
                    const item = res.items[i]
                    addItems(item)}
               
           })


    }, [isLoading])

    useEffect(() => {
        setIsLoading(true)
        setPager({
            page,
            count,
            handleChangePage: handlePaginationChange,
            rowsPerPage: itemsPerPage,
            handleChangeRowsPerPage,
        })
    }, [page, itemsPerPage])

    useEffect(() => {
        
        setPager({
            page,
            count,
            handleChangePage: handlePaginationChange,
            rowsPerPage: itemsPerPage,
            handleChangeRowsPerPage,
        })
    }, [count])

    const handlePaginationChange = (event: unknown, value: number) => setPage(value)

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number.isNaN(event.target.value)) {
            setItemsPerPage(10)
            return
        }
        setItemsPerPage(Number.parseInt(event.target.value))
    }

    const fields: Field<ProjectAnalysis>[] = [
        {
            sortable: true,
            label: t('name'),
            name: 'name',
        },
        {
            sortable: true,
            label: t('nameHer'),
            name: 'nameHer',
        },
        {
            sortable: true,
            label: t('nameHim'),
            name: 'nameHim',
        },
        {
            sortable: true,
            label: t('analizeBy'),
            name: 'infoAnalizedBy',
        },
        {
            sortable: true,
            label: t('validateBy'),
            name: 'infoValidateBy',
        },
        {
            label: t('creationDate'),
            name: 'creationDate',
            renderFunc: (f, i) => {
                const date = new Date(i.creationDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear()); // Convertir a string
            
                // Suponiendo que tienes el formato en una variable
                const format = t('dateFormat'); // o "dd/MM/yyyy", dependiendo del idioma
            
                return format
                    .replace('MM', month)
                    .replace('dd', day)
                    .replace('yyyy', year);
            }            
        },
    ]

    const downloadResults = (project: ProjectAnalysis) => {
        projectService.downloadResults(project.id).subscribe((f) => {
            f && downloadFile(f.name, f.mimeType, f.data)
        })
    }

    const actions: Actions<ProjectAnalysis> = {
        actionsColumn: t('Actions'),
        items: [
            {
                handler: (item) => navigate(URL_SHOW_PROYECT.replace(':patientID', '').replace(':id', item.id || "")),
                icon: seeIcon,
                label: t('Show'),
                hidden: (item) => item.id != selectedProject?.id,
            },
            {
                handler: (item) =>
                    navigate(URL_PROYECTPGSEC.replace(':patientID', '').replace(':id', item.id)),
                icon: editIcon,
                label: t('Edit'),
                hidden: (item) => item.id != selectedProject?.id,
            },
            {
                handler: downloadResults,
                icon: downloadIcon,
                label: t('Download'),
                hidden: (item) => item.id != selectedProject?.id,
            },
        ],
    }

    const sortable: Sort<ProjectAnalysis> = {
        name: sort.field,
        direction: sort.desc ? 'desc' : 'asc',
        handleSort: (field) => {
            setSort({field: field, desc: sort.field === field ? !sort.desc : true})
            setIsLoading(true)
        },
    }

    const validate = async () => {
        props.increaseStep()
    }

    const handleCheck = (p: ProjectAnalysis) => {
        setSelectedProject(p)
        props.handleSelectProject(p)
    }

    const search: Search<ProjectQuery> = {
        searchValues: searcher,
        handleSearch: (svs: SearchValue<ProjectQuery>[]) => {
            const result: SearchValue<ProjectQuery>[] = []
            svs.forEach((s) => {
                if (s.type === 'date' && s.value) {
                    result.push(Object.assign({...s}, {value: new Date(s.value as string).toISOString()}))
                } else {
                    result.push(s)
                }
            })
            setSearcher(result)
            setIsLoading(true)
        },
    }

    const createProyect = () => setModalCreateProject(true)

    const [modalCreateProject, setModalCreateProject] = useState<boolean>(false)
    const [selectedPatient, setSelectedPatient] = useState<any>()
    const [patients, setPatients] = useState<any[]>()


    const setPatientSelectedForCreate = (value: any) => {
        setSelectedPatient(value)
    }

    const closeModalCreateProject = () => setModalCreateProject(false)

    const actionsModal: FormAction[] = [
        {
            label: t('close'),
            handleAction: closeModalCreateProject,
        },
    ]


    useEffect(() => {
        if (!isLoading) {
            return
        }
        setIsLoading(true)
        let query: (QueryParamN<PatientQuery>)[] = [] 
        
    
        patientService.getFilteredListPGD(new Query<PatientQuery>({query})).subscribe((res) => {
            let filtered = res.items.filter(item => item.pgsec === true);
            if (loggedUser.clinics.length == 1) {
                filtered = filtered.filter(item => item.clinicID === loggedUser.clinics[0]);
            }
            setSelectedPatient(filtered[0])
            
            let auxPatient: any[] = []
            filtered.forEach((p) => {
                auxPatient.push({
                    value: p.id,
                    label: p.firstName + ' ' + p.lastName,
                })
            })
            setPatients(auxPatient)
        })
    }, [isLoading]);


    const createProject = () => {
        navigate(URL_PROYECTPGSEC.replace(':patientID',  selectedPatient.value || '').replace(':id', 'create'))
    }


    return (
        <>
            <Grid item xs={12}>
                <Button
                    className={classes.button}
                    variant={'contained'}
                    onClick={createProyect}
                    style={{float: 'right'}}>
                    {t('new')}
                </Button>
                <AppTable
                    styleHeader={{color: COLOR_PRIMARY}}
                    actions={actions}
                    fields={fields}
                    items={items}
                    rowKeyField={'patientID'}
                    pager={pager}
                    sort={sortable}
                    includesCheck={true}
                    handleCheck={handleCheck}
                    selectedID={selectedProject?.id || ""}
                    search={search}
                />
            </Grid>
            <Grid item xs={12}>
                <Box mt={3}>
                    <Button onClick={validate} disabled={!selectedProject}>{t('continue')}</Button>
                </Box>
            </Grid>

            <Modal open={modalCreateProject}>
                <Box className={styles2.modal}>
                    <form onSubmit={createProject}>
                        <FormControl variant="outlined" fullWidth>
                            <Box mt={2} textAlign={'justify'}>
                                {t('selectPatient')}
                            </Box>
                            <Autocomplete
                                disablePortal
                                style={{width: '100%', marginTop: '5%'}}
                                fullWidth
                                id={'patient'}
                                onChange={(_, value) => value && setPatientSelectedForCreate(value)}
                                options={patients || []}
                                sx={{width: 300}}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                getOptionLabel={(option) => option.label || ''}
                                value={selectedPatient || null}
                                renderInput={(params) => (
                                    <TextField variant="outlined" {...params} label={t('patient')} />
                                )}
                            />
                            <FormActions actions={actionsModal} message={t('confirm')} />
                        </FormControl>
                    </form>
                </Box>
            </Modal>


        </>
    )
}
