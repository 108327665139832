import {ProjectAnalysis} from "../../modules/projects/models/Project";
import {Button} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {commonStyles} from "../../common/styles/Styles";
import {useTranslation} from "react-i18next";
import { useState, useEffect } from 'react';
import cabecera from '../../assets/resultPFD/cabecera-pdf.png'
import firma from '../../assets/resultPFD/firma.png'

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import styles from './FourthStep.module.css'
import { getTestContainer } from "container/test-module";
import { TestService } from "modules/tests/services/TestService";
import { TEST_SERVICE_KEY } from "modules/tests";
import { Embryo, FinalStatusIndividual, Report, Status, StatusIndividual } from "../../modules/tests/models/Reports"
import { RolePGDTest } from "modules/users/enums/RoleType";
import { InformativeSNP } from "./Form";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { Individual } from "modules/individuals/models/Individual";

const testService = getTestContainer().get<TestService>(TEST_SERVICE_KEY)


type SecondStepProps = {
    increaseStep?: () => void
    deleteFiles?: () => void
    decreaseStep?: () => void
    project?: ProjectAnalysis

    patientInfo?: InformativeSNP
    partnerInfo?: InformativeSNP
}

export const FourthStep = (props: SecondStepProps) => {

    const [data, setData] = useState<Report>();
    const isGene = data?.Panel.genRegion === "Gene"
    const styles2 = commonStyles()
    const {t} = useTranslation()

    useEffect(() => {
     testService.report(
        props.project?.id || ""
     ).subscribe((res) => {
            setData(res)
        });
    }, [props.project?.id]);
    
    const formatDate = (dateS: Date | undefined) => {
        if (dateS === undefined ) return "No data"
        const date = new Date(dateS)

        const day = String(date.getDate()).padStart(2, '0');  
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const year = date.getFullYear(); 
        
        return `${day}/${month}/${year}`;
      }
      const colorP = (status: FinalStatusIndividual | undefined, indx: number) => {
        if (indx === 1) {
          if (status === 0 || status === 2) return '#90EE90'
          else return '#FF6347'
        } else  {
          if (status === 0 || status === 1) return '#90EE90'
          else return '#FF6347'
        }
      }
  
      const colorPEmbryo = (embryo: Embryo | undefined, indx: number) => {
          var color: string
          if (indx === 1) {
              color = embryo?.ColorA1 != null ?  embryo?.ColorA1.slice(1, -1)  : ''
              if (color.length > 7)  return "#F2EE81" 
              if (embryo?.FinalStatus === 0 || embryo?.FinalStatus === 2) return '#90EE90'
              else return '#FF6347'
          } else  {
              color = embryo?.ColorA2 != null ?  embryo?.ColorA2.slice(1, -1)  : ''
              if (color.length > 7)  return "#F2EE81" 
              if (embryo?.FinalStatus === 0 || embryo?.FinalStatus === 1) return '#90EE90'
              else return '#FF6347'
          }
        }
  
        const isLR = (embryo: Embryo | undefined, indx: number) => {
          var color: string
          if (indx === 1) {
              color = embryo?.ColorA1 != null ?  embryo?.ColorA1.slice(1, -1)  : ''
              if (color.length > 7)  return "LR" 
              else return 'P1'
          } else  {
              color = embryo?.ColorA2 != null ?  embryo?.ColorA2.slice(1, -1)  : ''
              if (color.length > 7)  return "LR" 
              else return 'P2'
          }
        }

     const isFamiliDirect = (rol: RolePGDTest | undefined) => {
        if (rol === 1 || rol === 2 || rol === 3 ) return true
        else return false
     }
    

     const roleIndividual = (status: RolePGDTest | undefined) => {
        switch (status) {
            case 0: 
                return "Female\nFather"
            case 1: 
                return " \nFemale"
            case 2: 
                return " \nMale"
            case 3: 
                return " \nChild"
            case 4: 
                return "Female\nMother"
            case 5: 
                return "Male\nMother"
            case 6: 
                return "Male\nFather"

        }
     }
      
     const StatusEmbryo = (embryos: FinalStatusIndividual | undefined) => {
        switch (embryos) {
            case 0: 
                return 'Non Carrier' 
            case 1: 
                return "Carrier From Mother"
            case 2: 
                return "Carrier From Father"
            case 3: 
                return "Affected"
            case 4: 
                return 'Null' 
        }
     }

     const halfColor = (colorS: string, position: number) => {
        if (colorS === "" ) return '#ddeafb' 
        colorS = colorS?.slice(1, -1);

        if(colorS?.length === 7) {
            return colorS
        } else {
            const color = colorS?.split(",")

            return color[position]
        }
     }

     const cutName = (nombre: string= "") => {
        return nombre.replace(/_/g, " ")

         
     }

     const inheritanceType = (acronym: string = ""): string => {
        switch (acronym){
            case "AR":
                return "AR - Autosomical Recessive"
            case "AD": 
                return "AR - Autosomical Recessive"
            case "XLR":
                return "XLR - X-Linked"
            default:
                return ""
        }
     }

     const reorderIndividuals = (individuals: Status[] = []) => {
         var aux: RolePGDTest[] = [4,0,5,6,1,2,3]
         
         var reorder: Status[] = individuals.sort((a, b) => {
            return aux.indexOf(a.RolePGDTest) - aux.indexOf(b.RolePGDTest);
         })


           return reorder
     }

    const generateHeader = () => {
        return (<header>
        <img src={cabecera} alt=""  style={{width: '100%', height: 'auto'}} />
        <div style={{marginBottom: 10}}>
            <table className={styles.dataTable}>
                <tr >
                    <td className={styles.name}><b>Name:</b></td>
                    <td className={styles.name}>{data?.Name}</td>
                    <td className={styles.name}><b>Name:</b></td>
                    <td className={styles.name}>{data?.NamePartner}</td>
                </tr>
                <tr>
                    <td className={styles.dataTabletd}><b>Birth date:</b></td>
                    <td className={styles.dataTabletd}>{formatDate(data?.Birthdate)}</td>
                    <td className={styles.dataTabletd}><b>Birth date:</b></td>
                    <td className={styles.dataTabletd}>{formatDate(data?.BirthdatePartner)}</td>
                </tr>
                <tr>
                    <td className={styles.dataTabletd}><b>CHN/DNI:</b></td>
                    <td className={styles.dataTabletd} >{data?.PatientId}</td>
                    <td className={styles.dataTabletd}><b>CHN/DNI:</b></td>
                    <td className={styles.dataTabletd} >{data?.PatientIdPartner}</td>
                </tr>
                <tr>
                    <td className={styles.dataTabletd}><b>Sex:</b></td>
                    <td className={styles.dataTabletd} >{Number(data?.Gender) === 1 ? "male" : "female"}</td>
                    <td className={styles.dataTabletd}><b>Sex:</b></td>
                    <td className={styles.dataTabletd} >{Number(data?.GenderPartner) === 1 ? "male" : "female"}</td>
                </tr>
                <tr>
                    <td className={styles.dataTabletd}><b>Sample type:</b></td>
                    <td className={styles.dataTabletd} >{data?.SampleType}</td>
                    <td className={styles.dataTabletd}><b>Sample type:</b></td>
                    <td className={styles.dataTabletd} >{data?.SampleTypePartner}</td>
                </tr>
                <tr>
                    <td className={styles.datatdLast}><b>Sample arrival date:</b></td>
                    <td className={styles.datatdLast} >{formatDate(data?.SampleDate)}</td>
                    <td className={styles.datatdLast}><b>Sample arrival date:</b></td>
                    <td className={styles.datatdLast} >{formatDate(data?.SampleDatePartner)}</td>
                </tr>
                <tr>
                    <td className={styles.reportDate} colSpan={4}><b>Report date:</b> {formatDate(data?.ReportDate)}</td>
                </tr> 
    
            </table>
        </div>
    </header>)

    } 

    const generatePDF = () =>{
        
        return (
        <div style={{textAlign: 'left'}}>
        <div style={{width: '100%', fontSize: '1.6em', padding: '0 50px 0 50px '}}  id="pdf-content">
        {/* <div style={{width: '100%', fontSize: '1.6em', padding: '0 50px 0 50px' , position: 'absolute', left: '-9999px'}}  id="pdf-content"> */}
         
                <header>
                    <img src={cabecera} alt=""  style={{width: '100%', height: 'auto'}} />
                    <div style={{marginBottom: 10}}>
                        <table className={styles.dataTable}>
                            <tr >
                                <td className={styles.name}><b>Name:</b></td>
                                <td className={styles.name}>{data?.Name}</td>
                                <td className={styles.name}><b>Name:</b></td>
                                <td className={styles.name}>{data?.NamePartner}</td>
                            </tr>
                            <tr>
                                <td className={styles.dataTabletd}><b>Birth date:</b></td>
                                <td className={styles.dataTabletd}>{formatDate(data?.Birthdate)}</td>
                                <td className={styles.dataTabletd}><b>Birth date:</b></td>
                                <td className={styles.dataTabletd}>{formatDate(data?.BirthdatePartner)}</td>
                            </tr>
                            <tr>
                                <td className={styles.dataTabletd}><b>CHN/DNI:</b></td>
                                <td className={styles.dataTabletd} >{data?.PatientId}</td>
                                <td className={styles.dataTabletd}><b>CHN/DNI:</b></td>
                                <td className={styles.dataTabletd} >{data?.PatientIdPartner}</td>
                            </tr>
                            <tr>
                                <td className={styles.dataTabletd}><b>Sex:</b></td>
                                <td className={styles.dataTabletd} >{Number(data?.Gender) === 1 ? "male" : "female"}</td>
                                <td className={styles.dataTabletd}><b>Sex:</b></td>
                                <td className={styles.dataTabletd} >{Number(data?.GenderPartner) === 1 ? "male" : "female"}</td>
                            </tr>
                            <tr>
                                <td className={styles.dataTabletd}><b>Sample type:</b></td>
                                <td className={styles.dataTabletd} >{data?.SampleType}</td>
                                <td className={styles.dataTabletd}><b>Sample type:</b></td>
                                <td className={styles.dataTabletd} >{data?.SampleTypePartner}</td>
                            </tr>
                            <tr>
                                <td className={styles.datatdLast}><b>Sample arrival date:</b></td>
                                <td className={styles.datatdLast} >{formatDate(data?.SampleDate)}</td>
                                <td className={styles.datatdLast}><b>Sample arrival date:</b></td>
                                <td className={styles.datatdLast} >{formatDate(data?.SampleDatePartner)}</td>
                            </tr>
                            <tr >
                             <td className={styles.reportDate} colSpan={4}><b>Report date:</b> {formatDate(data?.ReportDate)}</td>

                            </tr> 
                
                        </table>
                    </div>
                </header>

                <h1 className={styles.titleP} style={{textAlign: 'center'}}>Informativity Study for {data?.KeySnp}</h1>
                <h2 className={styles.titleP} style={{textAlign: 'center'}}>{data?.Region}</h2>
                <div className="">
                    <div className="">
                        <h2 className={styles.titleP}>Indication</h2>
                        <p>The Informative Study is a crucial preliminary step before performing Preimplantation Genetic Testing for Monogenic disorders 
                        (PGT-M), to select embryos that are not affected by a specific genetic disease. The process includes a linkage analysis, examining
                        hundreds of polymorphisms around the region of interest to identify the risk haplotype. Additionally, whenever possible, a direct 
                        analysis is conducted to detect the disease-causing mutation. To achieve accurate results, this type of study generally requires the 
                        participation of one or more family members of the couple.</p>
                    </div>
                    <div className="">
                        <h2 className={styles.titleP}>Results</h2>
                        <p>After the analysis of {data?.TotalSNP} polymorphisms, {data?.TotalAnalyzedSNPINFO + " "} 
                        informative polymorphisms were detected which will allow to analyze the embryos successfully (Table 1).</p>
                    </div>
                </div>
                <div >
                    <div className={styles.titleP}>Table 1. Results obtained from informativity study with PGD-SEQ kit.</div>
                    <table className={styles.patientTable}>
                        <tr>
                            <th colSpan={6} className={styles.titleTable} >Results from informativity study</th>
                        </tr>
                        <tr style={{textAlign: 'left'}}>
                            <th>Region</th>
                            { isGene && <td colSpan={5}>{data?.KeySnp}</td>}
                        </tr>
                        <tr style={{textAlign: 'left'}}>
                            <th>Chromosome</th>
                            <td colSpan={5}>{data?.Panel.chr} {isGene && `(${data?.Panel.transcrit} as canonical transcript)`}</td>
                        </tr>
                        <tr style={{textAlign: 'left'}}>
                            <th>Chromosomic region</th>
                            <td colSpan={5}>{props.project?.firstSNP} – {props.project?.lastSNP} (Human GRCh37) </td>
                        </tr>
                        <tr style={{textAlign: 'left'}}>
                            <th>Inheritance pattern</th>
                            <td colSpan={5}>{inheritanceType(data?.Panel.herencyPatern)}</td>
                        </tr>
                        <tr>
                            <th rowSpan={2}>Members of the study</th>
                            <th rowSpan={2}>CALL RATE</th>
                            <th colSpan={3}>Number of informative SNPs</th>
                        </tr>
                        <tr>
                            <th>Upstream region</th>
                            <th>Intragenic region</th>
                            <th>Downstream region</th>
                        </tr>
                        <tr>
                            <td>{data?.Name}</td>
                            <td>{data?.ValidSNP} / {data?.TotalSNP}</td>
                            <td>{props.patientInfo?.upstream} ({data?.KeySnp})</td>
                            <td>{props.patientInfo?.intrangenic} ({data?.KeySnp})</td>
                            <td>{props.patientInfo?.downstream} ({data?.KeySnp})</td>
                        </tr>
                        <tr>
                            <td>{data?.NamePartner}</td>
                            <td>{data?.ValidSNPPartner} / {data?.TotalSNPPartner }</td>
                            <td>{props.partnerInfo?.upstream} ({data?.KeySnp})</td>
                            <td>{props.partnerInfo?.intrangenic} ({data?.KeySnp})</td>
                            <td>{props.partnerInfo?.downstream} ({data?.KeySnp})</td>
                        </tr>
                    </table>
                </div>
                <br /><br /><br /><br /> <br /><br /><br /><br /> <br /><br />
                <header>
                    <img src={cabecera} alt=""  style={{width: '100%', height: 'auto'}} />
                    <div style={{marginBottom: 10}}>
                        <table className={styles.dataTable}>
                            <tr >
                                <td className={styles.name}><b>Name:</b></td>
                                <td className={styles.name}>{data?.Name}</td>
                                <td className={styles.name}><b>Name:</b></td>
                                <td className={styles.name}>{data?.NamePartner}</td>
                            </tr>
                            <tr>
                                <td className={styles.dataTabletd}><b>Birth date:</b></td>
                                <td className={styles.dataTabletd}>{formatDate(data?.Birthdate)}</td>
                                <td className={styles.dataTabletd}><b>Birth date:</b></td>
                                <td className={styles.dataTabletd}>{formatDate(data?.BirthdatePartner)}</td>
                            </tr>
                            <tr>
                                <td className={styles.dataTabletd}><b>CHN/DNI:</b></td>
                                <td className={styles.dataTabletd} >{data?.PatientId}</td>
                                <td className={styles.dataTabletd}><b>CHN/DNI:</b></td>
                                <td className={styles.dataTabletd} >{data?.PatientIdPartner}</td>
                            </tr>
                            <tr>
                                <td className={styles.dataTabletd}><b>Sex:</b></td>
                                <td className={styles.dataTabletd} >{Number(data?.Gender) === 1 ? "male" : "female"}</td>
                                <td className={styles.dataTabletd}><b>Sex:</b></td>
                                <td className={styles.dataTabletd} >{Number(data?.GenderPartner) === 1 ? "male" : "female"}</td>
                            </tr>
                            <tr>
                                <td className={styles.dataTabletd}><b>Sample type:</b></td>
                                <td className={styles.dataTabletd} >{data?.SampleType}</td>
                                <td className={styles.dataTabletd}><b>Sample type:</b></td>
                                <td className={styles.dataTabletd} >{data?.SampleTypePartner}</td>
                            </tr>
                            <tr>
                                <td className={styles.datatdLast}><b>Sample arrival date:</b></td>
                                <td className={styles.datatdLast} >{formatDate(data?.SampleDate)}</td>
                                <td className={styles.datatdLast}><b>Sample arrival date:</b></td>
                                <td className={styles.datatdLast} >{formatDate(data?.SampleDatePartner)}</td>
                            </tr>
                            <tr>
                                <td className={styles.reportDate} colSpan={4}><b>Report date:</b> {formatDate(data?.ReportDate)}</td>

                            </tr> 
                
                        </table>
                    </div>
                </header>
                <table className={styles.individualTable}>
                    <thead>
                        <th>Individual</th>
                        <th>State</th>
                        <th>Mutations</th>
                    </thead>
                    <tbody>
                  
                    {reorderIndividuals(data?.Status).map((item, index) => (
                            <tr key={index}>
                                <td>{roleIndividual(item.RolePGDTest)}</td>
                                <td>{StatusEmbryo(item.StatusIndividual)}</td>
                                <td>{item.Mutation}</td>
                            </tr>
                        ))}
                    </tbody>

                </table>


                <div key={"leyend"} className={styles.leyendDiv}>
                    <div className={styles.leyend}><div className={styles.boxColor1}></div>Healthy allele</div>
                    <div className={styles.leyend}><div className={styles.boxColor2}></div>Carrier allele</div>
                    <div className={styles.leyend}><div className={styles.boxColor3}></div>Likely recombinant</div>
                </div>
                <div className={styles.divGen}>
                {
                   reorderIndividuals(data?.Status).map((indi) => (
                        <div className={styles.tableGen}>
                            <div className={styles.columnGen}>
                                <div className={styles.headerGen}>{roleIndividual(indi.RolePGDTest)}</div>
                                <div className={styles.rowGen}>
                                    <div className={styles.genAfected} style={{backgroundColor: colorP(indi.StatusIndividual,1)}}>P1</div>
                                <div className={styles.genAfected} style={{backgroundColor: colorP(indi.StatusIndividual,2)}}>P2</div>
                                </div>
                                <div className={styles.rowGen}>
                                    <div className={
                                        (indi.RolePGDTest === 3 || indi.RolePGDTest === 1)? styles.femaleA1 :
                                            (indi.RolePGDTest === 2)? styles.maleA1 : 
                                                (indi.RolePGDTest === 0 )? styles.femaleA2 :
                                                (indi.RolePGDTest === 4 )? styles.femaleA1 :
                                                (indi.RolePGDTest === 5)? styles.maleA1 :styles.maleA2


                                    }></div>
                                    <div className={
                                      (isFamiliDirect(indi.RolePGDTest))? (
                                         (indi.RolePGDTest === 3)? styles.maleA1 : 
                                            (indi.RolePGDTest === 2)? styles.maleA2 : styles.femaleA2
                                        ) : 
                                        styles.greyA}></div>
                                </div>
                                <div className={styles.label}>{StatusEmbryo(indi.StatusIndividual)}</div>
                            </div>
                        </div>
                    ))
                }
                </div>
    
                <div>
                    <h2 className={styles.titleP}>Methodology</h2>
                    <p>
                        The informativity study is conducted by analyzing over 200 Single Nucleotide Polymorphisms (SNPs) 
                        located 2 Mb upstream and downstream of the gene or region of interest, covering a total of 4 Mb.
                        Bioinformatics tools are employed to select SNPs with the highest probability of being informative 
                        in the studied populations. These SNPs are used to establish the haplotypes of the samples, thereby
                        determining if the available information is optimal to proceed with the embryo study. Additionally,
                        full sequencing of the target gene is incorporated to facilitate direct analysis of the variants 
                        under study.
                    </p>
                    <p>
                        The DNA samples are employed to prepare Ion AmpliSeq, PGD-SEQ kit (Journey Genomics) and Ion S5 platform (Thermo Fisher Scientific)
                        Hybridization libraries using the PGD-SEQ kit (Journey Genomics). These libraries undergo sequencing on
                        the Illumina sequencing platforms (Illumina).
                    </p>
                    <p>
                        The interpretation provided in this report serves as a guideline. Decisions made following the issuance 
                        of these results are at the discretion of the clinic, including advice on the need for genetic counseling 
                        or additional diagnostic tests. Any test should be interpreted in the context of all available clinical 
                        findings. 
                    </p>
                <br /><br /><br /><br /> <br />
                {generateHeader()}
                    <p>
                        The analysis of results and report generation have been carried out using Journey Genomics 
                        software. Accuracy of the results is based on the assumption that samples received were correctly 
                        identified, family relationships are true, and the clinical diagnosis of relatives is correct.   </p>
                </div>
               
                <div>
                    <h2 className={styles.titleP}>PGT-M study limitations</h2>
                    <ul className={styles.list}>
                        <li>  
                            <p><span className="dash">- </span>
                            A prior informativity study has been conducted for this test, which has been classified as SUITABLE.
                            Every PGT-M study comprises two parallel studies: a direct study (which may be optional, depending on the
                            variant to be analyzed) and an indirect study, which is indispensable and is initiated in the informativity study.</p>
                        </li>
                        <li> 
                            <p> <span className="dash">- </span>
                            This test is designed exclusively to detect the variants indicated by the clinic or patients. 
                            No other variants or diseases not previously indicated are analyzed.</p>
                        </li>
                        <li>  
                            <p><span className="dash">- </span>
                            The embryonic status (defined as carrier, affected, or non-carrier) refers exclusively to the 
                            disease described in the Indication section, always based on the information provided by the clinic 
                            or patients regarding the presence or absence of the alteration(s) in each of the family members participating in the study.</p>
                        </li>
                        <li>  
                            <p><span className="dash">- </span>
                            This test represents only a small part of the embryo and may not necessarily represent the content of the entire embryo.</p>
                        </li>
                        <li>  
                            <p><span className="dash">- </span>
                            The PGT-M study is subject to artifacts that may lead to a misclassification of the embryo's status. 
                            These artifacts mainly include mosaicism and allele drop-out; the presence of maternal or external contamination cannot 
                            be completely ruled out. The entire PGT-M study is designed to minimize the possibility of diagnostic error due to these 
                            factors, and their presence can be detected at various points in the process. These artifacts are defined as follows:</p>
                            <ul>
                                <li>
                                    <p>Mosaicism: A mosaic embryo is one that has two or more cell lines with different genetic compositions. 
                                    In the case of a specific disease, a mosaic embryo would have genetic material from both alleles inherited 
                                    from the affected parent.</p>
                                </li>
                                <li>
                                    <p>Allele drop-out: During the laboratory process, since it is not possible to specifically guide the chemical 
                                    reactions in each sample, one allele of the embryo may be preferentially represented over the other. 
                                    In this way, only one of the alleles present in the sample would be shown, while the other would remain hidden. 
                                    If this occurs with the allele carrying the alteration, the embryo could appear non-carrier while actually being 
                                    a carrier. This is the main reason why a direct study alone is not recommended in PGT-M techniques, and an 
                                    additional indirect study is performed.</p>
                                </li>
                            </ul>
                        </li>
                <br /><br /><br /><br /> <br /><br /><br/>
                {generateHeader()}

                        <li> 
                            <p><span className="dash">- </span>
                            The sensitivity and specificity of the technique are high. The error rate has been minimized through the application 
                            of various control points throughout the process. However, according to scientific publications, there is an approximate 7% 
                            probability of diagnostic error in the global PGT-M analysis. Therefore, since no technique is infallible, it is recommended 
                            to confirm the results in case of pregnancy.</p>
                        </li>
                    </ul>
                    <p>The percentages regarding the inheritance or transmission of traits or diseases are purely theoretical and involve numerous processes 
                    beyond our control. Hence, the final number of carrier, healthy, or affected embryos may not match expectations, considering these 
                    inheritance rules.</p>
                </div>
        
                <div>
                    <br />
                    <br />
                    {/* <h2 className={styles.titleP}>References</h2> */}
                        {/* FIND a la espera de las referencias */}
                        {/* <p className={styles.titleP}>Guías de la ESHRE y carpeta DGP &gt; 25. Informes genéticos &gt;  1. Informes de la competencia &gt; meter las referencias que meta la competencia.</p> */}
    
                </div>
                <div style={{display: 'flex', justifyContent: "end"}}>
                    <img style={{width: "30%"}} src={firma} alt="" />
                </div>

               
        </div>
    </div>
    
)
    }

    const generatePgtPdf = () => {

        return (
            <div style={{textAlign: 'left'}}>
            <div style={{width: '100%', fontSize: '1.6em', padding: '0 50px 0 50px '}}  id="pdf-content">
            {/* <div style={{width: '100%', fontSize: '1.6em', padding: '0 50px 0 50px' , position: 'absolute', left: '-9999px'}}  id="pdf-content"> */}
             {generateHeader()}
    
                    <h1 className={styles.titleP} style={{textAlign: 'center'}}>PGT-M study for {data?.KeySnp} </h1>
                    <h2 className={styles.titleP} style={{textAlign: 'center'}}>{data?.Region}</h2>

                    <div className="">
                        <div className="">
                            <h2 className={styles.titleP}>Indication</h2>
                           <p> PGT-M (Preimplantation Genetic Testing for Monogenic Diseases) study from an embryo 
                            biopsy obtained during an IVF cycle. This study is indicated after obtaining a conclusive 
                            result from an informativity study performed in family members.</p>
                        </div>
                        <div className="">
                            <h2 className={styles.titleP}>Results</h2>
                           <p> After the analysis of {data?.TotalSNP} polymorphisms, {data?.TotalAnalyzedSNPINFO+" "} informative polymorphisms were detected which will allow to analyze the embryos successfully (Table 1).
                           </p>
                        </div>
                    </div>
                    <div >
                        <div className={styles.titleP}>Table 1. Results obtained from PGT-M study with PGD-SEQ kit.</div>
                        <div className={styles.embryoContainer}>
                        <table className={styles.patientTable}>
                            <thead >
                                <th className={styles.titleTable} >Embryo ID</th>
                                <th className={styles.titleTable} >Embryo Status</th>
                            </thead>
                           <tbody>
                            {data?.Embryos.map((item, index) => (
                                  <tr key={index}>
                                        <td>{item.SampleName}</td>
                                        <td>{StatusEmbryo(item.FinalStatus)}</td>
                                  </tr>
                             ))}
                           </tbody>
                           
                        </table>
                    </div>
                    </div>
                    
                    { data?.Embryos.length === 8 && (
                         <div>
                            <br /><br /><br /><br /><br /><br /> <br /><br /><br /> <br /><br /><br /><br /><br />
                            { generateHeader()} 
                        </div>
                    )}
                    <table className={styles.individualTable}>
                        <thead>
                            <th>Sample Name</th>
                            <th>State</th>
                            <th>Sex</th>
                        </thead>
                        <tbody>
                      
                        {data?.Embryos.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.SampleName}</td>
                                    <td>{StatusEmbryo(item.FinalStatus)}</td>
                                    <td>{item.Sex}</td>
                                </tr>
                            ))}
                        </tbody>
    
                    </table>

                    { data?.Embryos.length === 3 && (
                         <div>
                            <br /><br /><br /><br /><br /><br /> <br /><br /><br /> <br /><br /><br /><br /><br /><br /><br />
                            { generateHeader()} 
                        </div>
                    )}
                     { data?.Embryos.length === 4 && (
                        <div>
                            <br /><br /><br /><br /><br /><br /> <br /><br /><br /> <br /><br /><br /><br /><br />
                            { generateHeader()} 
                        </div>
                    )}
                     { data?.Embryos.length === 5 && (
                        <div>
                            <br /><br /><br /><br /><br /><br /> <br /><br /><br /> <br /><br /><br />
                            { generateHeader()} 
                        </div>
                    )}
                    { data?.Embryos.length === 6 && (
                        <div>
                            <br /><br /><br /><br /><br /><br /> <br />
                            { generateHeader()} 
                        </div>
                    )}
                    { data?.Embryos.length === 7 && (
                        <div>
                            <br /><br /><br /><br />
                            { generateHeader()} 
                        </div>
                    )}

                <div key={"leyend"} className={styles.leyendDiv}>
                    <div className={styles.leyend}><div className={styles.boxColor1}></div>Healthy allele</div>
                    <div className={styles.leyend}><div className={styles.boxColor2}></div>Carrier allele</div>
                    <div className={styles.leyend}><div className={styles.boxColor3}></div>Likely recombinant</div>
                </div>
                    <h2 style={{ color: 'rgba(125,191,220,1)'}}>Family Menbers</h2>
                    <div className={styles.divGen}>
                    {
                        reorderIndividuals(data?.Status).map((indi) => (
                            <div className={styles.tableGen}>
                                <div className={styles.columnGen}>
                                    <div className={styles.headerGen}>{roleIndividual(indi.RolePGDTest)}</div>
                                    <div className={styles.rowGen}>
                                    <div className={styles.genAfected} style={{backgroundColor: colorP(indi.StatusIndividual,1)}}>P1</div>
                                    <div className={styles.genAfected} style={{backgroundColor: colorP(indi.StatusIndividual,2)}}>P2</div>
                                    </div>
                                    <div className={styles.rowGen}>
                                        <div className={
                                            (indi.RolePGDTest === 3 || indi.RolePGDTest === 1)? styles.femaleA1 :
                                                (indi.RolePGDTest === 2)? styles.maleA1 : 
                                                    (indi.RolePGDTest === 0 )? styles.femaleA2 :
                                                    (indi.RolePGDTest === 4 )? styles.femaleA1 :
                                                    (indi.RolePGDTest === 5)? styles.maleA1 :styles.maleA2
    
    
                                        }></div>
                                        <div className={
                                          (isFamiliDirect(indi.RolePGDTest))? (
                                             (indi.RolePGDTest === 3)? styles.maleA1 : 
                                                (indi.RolePGDTest === 2)? styles.maleA2 : styles.femaleA2
                                            ) : 
                                            styles.greyA}></div>
                                    </div>
                                    <div className={styles.label}>{StatusEmbryo(indi.StatusIndividual)}</div>
                                </div>
                            </div>
                        ))
                    }
                    </div>
        
                    { data?.Embryos.length === 1 && (
                         <div>
                            <br /><br /><br /><br /><br /><br /> <br /><br /><br />
                            { generateHeader()} 
                        </div>
                    )}
                     { data?.Embryos.length === 2 && (
                         <div>
                            <br /><br /><br /><br /><br /><br /> <br /><br />
                            { generateHeader()} 
                        </div>
                    )}
                    <h2 style={{ color: 'rgba(125,191,220,1)'}}>Embryos</h2>
                    <div className={styles.divGen}>
                    {
                        data?.Embryos.map((indi) => (
                            <div className={styles.tableGen}>
                                <div className={styles.columnGen}>
                                    <div className={styles.headerGen}>{cutName(indi.SampleName)}</div>
                                    <div className={styles.rowGen}>
                                    <div className={styles.genAfected} style={{backgroundColor: colorPEmbryo(indi,1)}}>{isLR(indi,1)}</div>
                                    <div className={styles.genAfected} style={{backgroundColor: colorPEmbryo(indi,2)}}>{isLR(indi,2)}</div>
                                    </div>
                                    <div className={styles.rowGen}>
                                        <div className={styles.embriocor}>
                                            <div className={styles.halfColoer} style={{backgroundColor: halfColor(indi.ColorA1, 0)}}></div>
                                            <div className={styles.halfColoer} style={{backgroundColor: halfColor(indi.ColorA1, 1)}}></div>
                                        </div>
                                        <div  className={styles.embriocor}>
                                            <div className={styles.halfColoer}style={{backgroundColor: halfColor(indi.ColorA2, 0)}}></div>
                                            <div className={styles.halfColoer}style={{backgroundColor: halfColor(indi.ColorA2, 1)}}></div>
                                        </div>
                                    </div>
                                    <div className={styles.label}>{StatusEmbryo(indi.FinalStatus)}</div>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                      
              
                    { data?.Embryos.length === 8 && (
                         <div>
                            <br /><br /> <br /><br /> <br />
                            { generateHeader()} 
                        </div>
                    )}
       
                    <div>
                        <h2 className={styles.titleP}>Methodology</h2>
                        <p>
                            The test is performed from a DNA sample isolated from an embryo biopsy. The DNA was amplified 
                            and after that the PGT-M study was performed using the PGD-SEQ kit (Journey Genomics) OR “aún por 
                            definer” and the Ion S5 platform (Thermo Fisher Scientifc) OR Illumina sequencing platforms (Illumina) by Next Generation Sequencing.
                        </p>
                        { data?.Embryos.length === 3 && (
                            <div>
                                <br /><br /><br /><br /><br /><br /> <br /><br /><br /> <br />
                                { generateHeader()} 
                            </div>
                        )}

                        { data?.Embryos.length === 4 && (
                            <div>
                                <br /><br /><br /><br /><br /><br /> <br /><br />
                                { generateHeader()} 
                            </div>
                        )}
                        { data?.Embryos.length === 5 && (
                        <div>
                             <br /><br /><br /><br /><br /><br /> <br /><br />
                            { generateHeader()} 
                        </div>
                    )}
                     { data?.Embryos.length === 6 && (
                        <div>
                             <br /><br /><br /><br /><br /><br /><br /><br /><br />
                            { generateHeader()} 
                        </div>
                    )}
                    { data?.Embryos.length === 7 && (
                        <div>
                            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                            { generateHeader()} 
                        </div>
                    )}
                        <p>
                            Previously to the PGT-M study, the polymorphisms surrounding the mutation(s) present in the family 
                            line are analyzed to obtain linkage information between these polymorphisms and the mutation of interest (informativity study). 
                            This information is subsequently used to determine the possible carrier, non-carrier or affected status of each embryo in an 
                            indirect manner. A direct study can be also performed additionally depending on the nature of the alteration and only if it is 
                            possible.
                        </p>
                        <p>
                            The interpretation provided in this report serves as guidance; the timing of embryo transfer is at the discretion of the clinic, 
                            including advice on the need for genetic counseling or diagnostic tests. Any test results should be interpreted in the context 
                            of all available clinical findings. The analysis of the results, including the determination of each embryo status, and report 
                            eneration have been carried out using
                        </p>
                        <p>
                            Journey Genomics software. Accuracy of the results is based on the assumption that samples received 
                            were correctly identified, family relationships are true, and the clinical diagnosis of relatives is correct.
                        </p>
                    </div>
                    { data?.Embryos.length === 1 && (
                         <div>
                            <br /><br /><br /><br /><br /><br /> <br /><br /><br />
                            { generateHeader()} 
                        </div>
                    )}
                     { data?.Embryos.length === 2 && (
                         <div>
                            <br /><br /><br /><br /><br /><br /> <br />
                            { generateHeader()} 
                        </div>
                    )}
                    <div>
                        <h2 className={styles.titleP}>PGT-M study limitations</h2>
                        <ul className={styles.list}>
                            <li>  
                                <p><span className="dash">- </span>
                                A prior informativity study has been conducted for this test, which has been classified as 
                                SUITABLE. Every PGT-M study comprises two parallel studies: a direct study (which may be 
                                optional, depending on the variant to be analyzed) and an indirect study, which is 
                                indispensable and is initiated in the informativity study.</p>
                            </li>
                            <li>  
                                <p><span className="dash">- </span>
                                This test is designed exclusively to detect the variants indicated by the clinic or patients. No other variants or diseases not previously indicated are analyzed</p>
                            </li>
                            <li>  
                                <p><span className="dash">- </span>
                                The embryonic status (defined as carrier, affected, or non-carrier) refers exclusively 
                                to the disease described in the Indication section, always based on the information provided 
                                y the clinic or patients regarding the presence or absence of the alteration(s) in each of the 
                                family members participating in the study.</p>
                            </li>
                            <li>  
                                <p><span className="dash">- </span>
                                This test represents only a small part of the embryo and may not necessarily represent the content of the entire embryo.</p>
                            </li>
              
       
                            <li>  
                                <p><span className="dash">- </span>
                                The PGT-M study is subject to artifacts that may lead to a misclassification of the embryo's status. These artifacts 
                                mainly include mosaicism and allele drop-out; the presence of maternal or external contamination cannot be completely ruled out. 
                                The entire PGT-M study is designed to minimize the possibility of diagnostic error due to these factors, and their presence can be 
                                detected at various points in the process. These artifacts are defined as follows:</p>
                                { data?.Embryos.length === 8 && (
                                    <div>
                                        <br /><br /> <br /><br /> <br />
                                        { generateHeader()}
                                        <br /> 
                                    </div>
                                )}
                                <ul>
                                    <li>
                                        <p>
                                        Mosaicism: A mosaic embryo is one that has two or more cell lines with different genetic compositions. 
                                        In the case of a specific disease, a mosaic embryo would have genetic material from both alleles inherited 
                                        from the affected parent.</p>
                                    </li>
                                    { data?.Embryos.length === 3 && (
                                        <div>
                                            <br /><br /><br /><br /><br /><br /> <br /><br />
                                            { generateHeader()} 
                                        </div>
                                    )}

                                    { data?.Embryos.length === 4 && (
                                        <div>
                                            <br /><br /><br /><br /><br /><br /> <br /><br />
                                            { generateHeader()} 
                                        </div>
                                    )}

                                    { data?.Embryos.length === 5 && (
                                        <div>
                                            <br /><br /><br /><br /><br /><br /> <br /><br />
                                            { generateHeader()} 
                                        </div>
                                    )}
                                    { data?.Embryos.length === 6 && (
                                        <div>
                                            <br /><br /><br /><br /><br /><br /> <br /><br /><br /> 
                                            { generateHeader()} 
                                        </div>
                                    )}
                                    { data?.Embryos.length === 7 && (
                                        <div>
                                            <br /><br /><br /><br /><br /><br /><br /><br />
                                            { generateHeader()} 
                                        </div>
                                    )}

                                    <li><p>
                                        Allele drop-out: During the laboratory process, since it is not possible to specifically guide 
                                        the chemical reactions in each sample, one allele of the embryo may be preferentially represented over the 
                                        other. In this way, only one of the alleles present in the sample would be shown, while the other would remain hidden. 
                                        If this occurs with the allele carrying the alteration, the embryo could appear non-carrier while actually being a 
                                        carrier. This is the main reason why a direct study alone is not recommended in PGT-M techniques, and an additional 
                                        indirect study is performed.</p>
                                    </li>
                                </ul>
                            </li>
                            <li>  <p><span className="dash">- </span>
                            The sensitivity and specificity of the technique are high. The error rate has been minimized through the application of 
                            various control points throughout the process. However, according to scientific publications, there is an approximate 7% 
                            probability of diagnostic error in the global PGT-M analysis 
                            (PMC4350594). Therefore, since no technique is infallible, it is recommended to confirm the results in case of pregnancy.</p>
                            </li>
                            <li><p>
                                The percentages regarding the inheritance or transmission of traits or diseases are purely theoretical and involve 
                                numerous processes beyond our control. Hence, the final number of carrier, healthy, or affected embryos may not match 
                                expectations, considering these inheritance rules.</p>
                            </li>
                        </ul>
                    </div>
                    { data?.Embryos.length === 1 && (
                         <div>
                            <br /><br /><br /><br /><br /><br /> <br />
                            { generateHeader()} 
                        </div>
                    )}

                    { data?.Embryos.length === 2 && (
                         <div>
                            <br /><br /><br /><br /><br /><br /> 
                            { generateHeader()} 
                        </div>
                    )}
                    <div>
                        <h2 className={styles.titleP}>References</h2>
                        {/* FIND a la espera de las referencias */}
                        {/* <p className={styles.titleP}>Guías de la ESHRE y carpeta DGP &gt; 25. Informes genéticos &gt;  1. Informes de la competencia &gt; meter las referencias que meta la competencia.</p> */}
    
                    </div>
                    <div style={{display: 'flex', justifyContent: "end"}}>
                        <img style={{width: "30%"}} src={firma} alt="" />
                    </div>
    
                   
            </div>
        </div>
        
    )
    }


    

    const downloadPDF = () => {
        const input = document.getElementById('pdf-content');
    
        if (input) {
            html2canvas(input).then((canvas) => {
                const pdf = new jsPDF();
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 190; // ancho de la imagen
                const pageHeight = pdf.internal.pageSize.height;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
    
                let position = 0;
    
                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
    
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
    
                pdf.save('result.pdf');
            });
        }
    };
    return (
        <>
        {/* {isDataLoaded? 
    (  */}
        <div>
            <Button
                variant="contained"
                startIcon={<ArrowBack/>}
                onClick={props.decreaseStep}
                className={styles2.button}
                style={{display: 'flex', alignSelf: 'flex-start'}}>
                {t('back')}
            </Button>

            <Button
                variant="contained"
               
                onClick={downloadPDF}
                className={styles2.button}
                style={{display: 'flex', alignSelf: 'flex-start'}}>
                {t('download')}
            </Button>
       

           {(data?.Embryos != undefined)?   generatePgtPdf() : generatePDF() }
    </div>

        </>
    )
}