import React, {useEffect, useRef, useState} from 'react'
import styles from './FourthStep.module.css'
import {ProjectAnalysis} from 'modules/projects/models/Project'
//@ts-ignore
import igv from '../../../node_modules/igv/dist/igv.esm'
import {Individual} from 'modules/individuals/models/Individual'
import {getIndividualContainer} from 'container/individual-module'
import {IndividualService} from 'modules/individuals/services/IndividualService'
import {INDIVIDUAL_SERVICE_KEY} from 'modules/individuals'
import {Query, QueryParam} from 'common/api/Query'
import {getFileContainer} from "../../container/file-module";
import {FILE_SERVICE_KEY} from "../../modules/files";
import {FileService} from "../../modules/files/services/FileService";
import {Embryo, toModel} from "../../modules/embryos/models/Embryo";
import {EMBRYO_SERVICE_KEY} from "../../modules/embryos";
import {EmbryoService} from "../../modules/embryos/services/EmbryoService";
import {getEmbryoContainer} from "../../container/embryo-module";

const individualService = getIndividualContainer().get<IndividualService>(INDIVIDUAL_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const embryoService = getEmbryoContainer().get<EmbryoService>(EMBRYO_SERVICE_KEY)

type SecondStepProps = {
    increaseStep?: () => void
    deleteFiles?: () => void
    decreaseStep?: () => void
    project?: ProjectAnalysis
}

type IndividualFiles = {
    individual?: Individual
    baiID: string
    bimID: string
    embryo?: Embryo
}

export const IGV = (props: SecondStepProps) => {
    const igvDivRef = useRef(null)
    const [individuals, setIndividuals] = useState<Individual[]>([])
    const [mapIndividualFiles, setMapIndividualFiles] = useState<IndividualFiles[]>([])
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        let iFilesArray: IndividualFiles[] = []

        // Cargar individuos
        individualService
            .getFilteredList(
                new Query({
                    query: [new QueryParam('informativityID', props.project?.informativityID || '')],
                })
            )
            .subscribe((res) => {
                setIndividuals(res.items)
                res.items.forEach((individual) => {
                    let ifiles: IndividualFiles = {
                        individual: individual,
                        baiID: individual.baiFileID,
                        bimID: individual.bimFileID
                    }
                    if (ifiles.baiID !== "" && ifiles.bimID !== "") {
                        iFilesArray.push(ifiles)
                    }
                })
            })

        // Cargar embriones
        embryoService.getFilteredList(new Query({query: [new QueryParam('projectID', props.project?.id || '')]})).subscribe((res) => {
            res.items.forEach((embryo) => {
                let ifiles: IndividualFiles = {
                    baiID: embryo.baiFileID,
                    bimID: embryo.bimFileID,
                    embryo: toModel(embryo)
                }
                if (ifiles.baiID !== "" && ifiles.bimID !== "") {
                    iFilesArray.push(ifiles)
                }
            })
            setMapIndividualFiles(iFilesArray)
            setIsLoaded(true)
        })
    }, [props.project])

    useEffect(() => {
        if (!isLoaded || mapIndividualFiles.length === 0) return

        const tracks = mapIndividualFiles.map((file) => ({
            type: 'alignment',
            format: 'cram',
            url: `https://backend-dev.journeygenomics.com/files/bimfile/${file.bimID}`,
            indexURL: `https://backend-dev.journeygenomics.com/files/baifile/${file.baiID}`,
            name: file.individual ? file.individual.sampleName : file.embryo?.sampleName
        }))

        const options = {
            genome: 'hg19',
            tracks: tracks,
        }
        igv.createBrowser(igvDivRef.current, options)
    }, [isLoaded, mapIndividualFiles])

    return (
        <div className={styles.container}>
            <div ref={igvDivRef}/>
        </div>
    )
}
