import { COLOR_PRIMARY } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { IUserService } from '../../modules/users/services/UserService'
import { getUserContainer } from '../../container/user-modules'
import { USER_SERVICE_KEY } from '../../modules/users'
import { User, UserQuery } from '../../modules/users/models/User'
import { navigate } from '@reach/router'
import { URL_USER } from '../../routes/routes-constants'
import { Box, Button } from '@material-ui/core'
import { useStyles } from '../matchings/Matching.styles'
import { RoleType } from '../../modules/users/enums/RoleType'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const Table = () => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<User[]>([])
  const [sort, setSort] = useState<SortParam<User>>({
    field: 'firstName',
    desc: true,
  })

  useEffect(() => {
    if (!isLoading) {
      return
    }
    setIsLoading(true)
    const query: QueryParam<UserQuery>[] = []
    if (!authService.get().permissions.find((p) => p === Permission.editAllUsers)) {
      query.push(new QueryParam<UserQuery>('role', RoleType.ClinicUser))
      query.push(new QueryParam<UserQuery>('clinics', authService.get().clinics))
    }
    
    userService
      .getTable(
        new Query({
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          query,
          sort: [{ field: sort.field, desc: sort.desc }],
        }),
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<User>[] = [
    {
      sortable: true,
      label: t('firstName'),
      name: 'firstName',
    },
    {
      sortable: true,
      label: t('lastName'),
      name: 'lastName',
    },
    {
      label: t('role'),
      name: 'role',
      renderFunc: (f, i) => t(RoleType[i.role]),
    },
    {
      sortable: true,
      label: t('email'),
      name: 'email',
    },
    {
      sortable: true,
      label: t('Clinics'),
      name: 'clinicName',
    },
    {
      sortable: true,
      label: t('createdAt'),
      name: 'createdAt',
      renderFunc: (f, i) => {
        const date = new Date(i.createdAt);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()); // Convertir a string
    
        // Suponiendo que tienes el formato en una variable
        const format = t('dateFormat'); // o "dd/MM/yyyy", dependiendo del idioma
    
        return format
            .replace('MM', month)
            .replace('dd', day)
            .replace('yyyy', year);
    } 
    },
  ]

  const editUser = (user: User) => navigate(URL_USER.replace(':id', `${user.id}`))

  const hideUser = (u: User) => !authService.userCan(Permission.editUsers) || u.id === authService.get().id

  const actions: Actions<User> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editUser,
        icon: editIcon,
        label: t('Edit'),
        hidden: hideUser,
      },
    ],
  }

  const sortable: Sort<User> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const createUsers = () => navigate(URL_USER.replace(':id', 'create'))

  return (
    <>
      <Box justifyContent={'space-between'} mb={2}>
        {authService.userCan(Permission.editUsers) && (
          <Button
            className={classes.button}
            variant={'contained'}
            style={{ float: 'right' }}
            onClick={createUsers}>
            {t('create')}
          </Button>
        )}
      </Box>
      <AppTable
        styleHeader={{ color: COLOR_PRIMARY }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'id'}
        pager={pager}
        sort={sortable}
      />
    </>
  )
}
