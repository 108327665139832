import {
    Accordion, AccordionDetails, AccordionSummary,
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Snackbar,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    TextField, Typography,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import {useTranslation} from 'react-i18next'
import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {Head} from 'components/table/Head'
import {AppTable, Field} from 'components/table'
import {Body} from 'components/table/Body'
import {Actions} from 'components/table/types'
import uploadIcon from '../../assets/table-icons/upload-icon.svg'
import {GenericTooltip} from 'components/generic-tooltip'
import {File as F, FileDTO} from '../../modules/files/models/File'
import {v4 as uuidv4, NIL as nullId} from 'uuid'
import {getAuthContainer} from 'container/auth-modules'
import {AuthService} from 'modules/auth/services/AuthService'
import {AUTH_SERVICE_KEY} from 'modules/auth'
import styles from './ThirdStep.module.css'
import {Query, QueryParam} from 'common/api/Query'
import {FormAction, FormActions} from '../../common/utils/form-generation'
import {commonStyles} from '../../common/styles/Styles'
import {Embryo, EmbryoDTO, EmbryosGroup, emptyEmbryoDTO, toModel} from 'modules/embryos/models/Embryo'
import {getEmbryoContainer} from 'container/embryo-module'
import {EmbryoService} from 'modules/embryos/services/EmbryoService'
import {EMBRYO_SERVICE_KEY} from 'modules/embryos'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import {dataToBase64, dataToBase642, downloadFile} from 'common/files/file'
import {ProjectAnalysis} from 'modules/projects/models/Project'
import {getProjectContainer} from 'container/project-module'
import {ProjectService} from 'modules/projects/services/ProjectService'
import {PROJECT_SERVICE_KEY} from 'modules/projects'
import {ModalPreview} from 'components/modals/modalPreview/ModalPreview'
import CircularProgress from '@mui/material/CircularProgress'
import {finalStatusTitle} from "../../modules/individuals/enums/Status";
import {firstValueFrom} from "rxjs";
import {getFileContainer} from "../../container/file-module";
import {FileService} from "../../modules/files/services/FileService";
import {FILE_SERVICE_KEY} from "../../modules/files";
import {ExpandMore} from "@material-ui/icons";
import {COLOR_CLINICS} from "../../routes/color-constants";
import {IGV} from "./IGV";
import {Alert} from "@material-ui/lab";
import { de } from 'date-fns/locale'
import { formatDate } from 'common/utils/DateUtils'
import { Permission } from 'common/enums/Permissions'
import { PatientService } from 'modules/patients/services/PatientService'
import { getPatientContainer } from 'container/patient-module'
import { PATIENT_SERVICE_KEY } from 'modules/patients'
import { ResultPatientData } from 'modules/script-executions/models/ResultPatientData'
import { IndividualService } from 'modules/individuals/services/IndividualService'


const DEFAULT_ROW_KEY = 'id'

type ThirdStepProps = {
    increaseStep: () => void
    decreaseStep: () => void
    deleteFiles: () => void
    project?: ProjectAnalysis
    setProject: (s: ProjectAnalysis) => void
    currentStep: number
}

interface File2 extends FileDTO {
    embryo: EmbryoDTO
}

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const embryoService = getEmbryoContainer().get<EmbryoService>(EMBRYO_SERVICE_KEY)
const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)


export const ThirdStep = (props: ThirdStepProps) => {
    const {t} = useTranslation()
    const [embryos, setEmbryos] = useState<EmbryoDTO[]>([])
    const genderOptions = ['male', 'female', 'unknown']
    const [lastAdded, setLastAdded] = useState<EmbryoDTO>(emptyEmbryoDTO())
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const fileInputRef2 = useRef<HTMLInputElement | null>(null)
    const fileInputRefBAMBAI = useRef<HTMLInputElement | null>(null);
    const [modalDeleteOpened, setModalDeleteOpened] = useState<boolean>(false)
    const [modalReplaceOpened, setModalReplaceOpened] = useState<boolean>(false)
    const [currentEmbryo, setCurrentEmbryo] = useState<EmbryoDTO>()
    const styles2 = commonStyles()
    const [project, setProject] = useState<ProjectAnalysis | undefined>(props.project)
    const [projectName, setProjectName] = useState<String>(props.project?.name || '')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [embryosGroup, setEmbryosGroup] = useState<EmbryosGroup[]>([])
    const [modalPreviewOpened, setModalPreviewOpened] = useState<boolean>(false)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [data, setData] = useState<any>()
    const [dataLoaded, setDataLoaded] = useState<boolean>(false)
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [isViewData, setIsViewData] = useState<boolean>(true)
    const [modalSaveGroupOpened, setModalSaveGroupOpened] = useState<boolean>(false)
    const [nameGroup, setNameGroup] = useState<string>('')
    const [err, setErr] = useState<string>()
    const [open, setOpen] = React.useState<boolean>(false)
    
    const [paretnFasadeId, setParetnFasadeId] = useState<string>()
    const [tableResult, setTableResult] = useState<string>()


    useEffect (()=> {
        if (project){
        projectService.getByID(project.id).subscribe(res => {
            setParetnFasadeId(res?.parentsFasadeID)
            setTableResult(res?.resultTableID)

        })
    }
    },[project])


    const openModalSaveGroup = () => {
     let groupName = embryosGroup.find((g) => g.id === project?.embryosGroupID)?.name || ''

        if (groupName == "" || groupName == undefined ||groupName == "New" ) setModalSaveGroupOpened(true)
            else saveGroup()
    }

    const closeSaveGroup = () => {
        setModalSaveGroupOpened(false)
    }

    const saveGroup = () => {
        let groupID = project?.embryosGroupID
        let groupName = embryosGroup.find((g) => g.id === project?.embryosGroupID)?.name || ''

        if(!authService.get().permissions.find((p) => p === Permission.deleteClinics)){


        let newEmbryoGroup = {
            id: groupID || "",
            name: nameGroup || groupName || '',
            clinicid: authService.get().clinics[0],
            projectid: project?.id,
            pgtype: 'pgd'
        }
        embryoService.updateEmbryoGroup(newEmbryoGroup).subscribe((res) => {
            setModalSaveGroupOpened(false)
        })}else{
            patientService.getByID(project?.patientID || '').subscribe((res) => {
                let newEmbryoGroup = {
                    id: groupID || "",
                    name: nameGroup || groupName || '',
                    clinicid: res?.clinicID || '',
                    projectid: project?.id,
                    pgtype: 'pgd'
                }
                embryoService.updateEmbryoGroup(newEmbryoGroup).subscribe((res) => {
                    setModalSaveGroupOpened(false)
                })
            })}
        handleContinue()
    }

    const handleNameGroup = (name: string, value: string) => {
        setNameGroup(value)
    }

    const refreshEmbryosGroup = () => {
        
  
            embryoService.getEmbryosGroup(new Query({ query: [new QueryParam('projectid' , project?.id || ''),
                new QueryParam('pgtype' , 'pgd')]})).subscribe((res) => {
          
                setEmbryosGroup(res.items)
        })

    }

    useEffect(() => {
        refreshEmbryosGroup()
    }, [])


    useEffect(() => {
        if (!isLoading) return
        embryoService
            .getFilteredList(
                new Query({
                    query: [new QueryParam('embryosGroupID', project?.embryosGroupID || '')],
                })
            )
            .subscribe((res) => {
                setEmbryos(res.items)
                setIsLoading(false)
                setShowSpinner(false)
                setIsViewData(false)
                setIsLoadingBamFiles(true)
            })
    }, [isLoading])

    const fields: Field<any>[] = [
        {
            sortable: false,
            searchable: false,
            label: t('name'),
            name: 'name',
        },
        {
            sortable: false,
            searchable: false,
            label: t('date'),
            name: 'date',
            renderFunc: (f, i) => {
                const date = new Date(i.date);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear()); // Convertir a string
            
                // Suponiendo que tienes el formato en una variable
                const format = t('dateFormat'); // o "dd/MM/yyyy", dependiendo del idioma
            
                return format
                    .replace('MM', month)
                    .replace('dd', day)
                    .replace('yyyy', year);
            },
        },
        {
            sortable: false,
            searchable: false,
            label: t('status'),
            name: 'status',
        },
        {
            sortable: false,
            searchable: false,
            label: t('gender'),
            name: 'gender',
        },
        {
            sortable: false,
            searchable: false,
            label: t('color'),
            name: 'color',
        },
    ]

    const changeFile = (f: any) => {
        setLastAdded(Object.assign({...lastAdded}, {file: f}))
        fileInputRef.current?.click()
    }

    const actions: Actions<any> = {
        actionsColumn: t('Actions'),
        items: [
            {
                handler: changeFile,
                icon: uploadIcon,
                label: t('upload'),
            },
        ],
    }


    const convertToFile = (f: File, data: string): FileDTO => ({
        id: uuidv4(),
        name: f.name,
        data,
        size: f.size,
        mimeType: f.type,
        extension: f.type.split('/')[1],
        ownerID: authService.get().id,
        pgType: ''
    })

    const handleFileInput2 = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const fl = (event.target as HTMLInputElement).files
        if ((!fl?.length) || (fl[0].name.substring(fl[0].name.length - 4, fl[0].name.length) !== '.vcf') ){
            setErr(t('fileMustHaveVcfExtension'))
            setOpen(true)
            return
        }


        const data = await dataToBase64(fl[0])
        if (currentEmbryo) {
            let file = new F(convertToFile(fl[0], data)).toDTO()
            file.extension = 'vcf'
            //@ts-ignore
            currentEmbryo.file = file
            currentEmbryo.fileName = fl[0].name

            setShowSpinner(true)
            embryoService.updateFile(currentEmbryo).subscribe(() => {
                setIsLoading(true)
            })
        }
    }

    const handleFileInput = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const fl = (event.target as HTMLInputElement).files
        if ((!fl?.length) || (fl[0].name.substring(fl[0].name.length - 4, fl[0].name.length) !== '.vcf') ){
            setErr(t('fileMustHavecfExtension'))
            setOpen(true)
            return
        }


        let rows = embryos?.length || 0

        Array.from(fl).forEach(async (f, i) => {
            const data = await dataToBase64(f)
            let file = new F(convertToFile(f, data)).toDTO()
            file.extension = 'vcf'

            let newEmbryo = Object.assign(
                {...lastAdded},
                {id: uuidv4()},
                {file: file},
                {embryoGender: lastAdded.embryoGender},
                {fileName: f.name},
                {projectID: project?.id},
                {embryosGroupID: project?.embryosGroupID},
                {embryo: rows + i}
            )

            setShowSpinner(true)
            embryoService.add(newEmbryo).subscribe((res) => {
                //@ts-ignore
                setProject(Object.assign({...project}, {embryosGroupID: res?.embryosGroupID}))
                setLastAdded(emptyEmbryoDTO())
                refreshEmbryosGroup()
                setIsLoading(true)
            })
        })
    }

    const parseDate = (dateToFormat: Date) => {
        const date = new Date(dateToFormat);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()); 
    
        
        const format = t('dateFormat'); 
    

        return format
            .replace('MM', month)
            .replace('dd', day)
            .replace('yyyy', year);
    }


    const deleteEmbryo = (emb: EmbryoDTO, ind: number) => {
        setCurrentEmbryo(emb)
        setCurrentIndex(ind)
        setModalDeleteOpened(true)
    }

    const deleteConfirmed = (event: any) => {
        event.preventDefault()

        setShowSpinner(true)
        embryoService.delete(currentEmbryo?.id || '').subscribe(() => {
            let newEmbryo = [...embryos]
            newEmbryo.splice(currentIndex, 1)

            const updatePromises = newEmbryo.map(async (emb, i) => {
                emb.embryo = i
                return embryoService.update(emb).toPromise()
            })
            Promise.all(updatePromises)
                .then(() => {
                    setModalDeleteOpened(false)
                    setIsLoading(true)
                    setLastAdded(emptyEmbryoDTO())
                    
                })
                .catch((error) => {
                    console.error(error)
                })
        })
    }

    const closeModalReplace = () => {
        setModalReplaceOpened(false)
        setCurrentEmbryo(undefined)
    }

    const actionsDelete: FormAction[] = [
        {
            label: t('close'),
            handleAction: closeSaveGroup,
        },
    ]

    const actionsReplace: FormAction[] = [
        {
            label: t('close'),
            handleAction: closeModalReplace,
        },
    ]

    const replaceConfirmed = (event: any) => {
        event.preventDefault()
        setModalReplaceOpened(false)
        fileInputRef2.current?.click()
    }

    const handleReplace = (ind: EmbryoDTO) => {
        setCurrentEmbryo(ind)
        setModalReplaceOpened(true)
    }

    const handleContinue = () => {
            props.increaseStep()
    }

    const handleUpdate = (index: number) => {
        setShowSpinner(true)
        embryoService.update(embryos[index]).subscribe((res) => {
            setIsLoading(true)
        })
    }
    
    const handleChangeArray = (name: string, value: string | Number, index: number) => {
        let auxArray = [...embryos]
        let updated = Object.assign({...auxArray[index]}, {[name]: value})
        //@ts-ignore
        auxArray[index] = updated
        setEmbryos(auxArray)
    }
    const handleChangeEmbryo = (e: string) => {
        setIsLoading(true)
        if (project) {
            embryos.forEach((embryo) => {
                let embryoAux = Object.assign({...embryo}, {projectID: nullId })
                embryoService.update(embryoAux)
            })
            if (e == undefined) {
                
                setEmbryos([])
                let auxProject = Object.assign(
                    {...project}, {
                        embryosGroupID: uuidv4(),
                        resultTableWithEmbryosID: nullId,
                        parentsFasadeID: paretnFasadeId,
                        resultTableID: tableResult
                        })
                setIsLoading(true)
                setProject(auxProject)
                props.setProject(auxProject)

                projectService.update(auxProject)
                return
            }

            let auxProject = Object.assign(
                {...project },{
                    embryosGroupID: e, 
                    resultTableWithEmbryosID: nullId,
                    parentsFasadeID: paretnFasadeId,
                    resultTableID: tableResult
                })

            
            setProject(auxProject)
            setShowSpinner(true)
            projectService.update(auxProject).subscribe((res) => {
                setIsLoading(true)
            })


        }
    }
    
    const runParentsSnipetsProcess = () => {
        setDataLoaded(false)
        setModalPreviewOpened(true)
        embryoService.runEmbryoSnipetsProcess(project?.id || '').subscribe((res: any) => {
            let aux = res;
            let auxPreprocessed = res.preProcessedData;
            auxPreprocessed = auxPreprocessed.map((item: any) => ({ ...item, seeEmbryo: true }));
            aux.preProcessedData = auxPreprocessed;
            setData(aux)
            setIsLoading(true)
            setDataLoaded(true)
        })
    }

    const [bamFiles, setBamFiles] = useState<File2[]>([])
    const [isLoadingBamFiles, setIsLoadingBamFiles] = useState<boolean>(true)
    const [isLoadingBamFiles2, setIsLoadingBamFiles2] = useState<boolean>(false)

    useEffect(() => {
        if (embryos.length == 0) {
            setIsLoadingBamFiles(false)
            return
        } /*else {
            embryos.forEach((embryo) => {
                let embryoAux = Object.assign({...embryo}, {projectID: project?.id})
                embryoService.update(embryoAux)
            })
        }*/

        if (!isLoadingBamFiles) {
            return
        }

        refreshData()
    }, [embryos, isLoadingBamFiles])

    const refreshData = async () => {

        embryos.forEach(element => {
            console.log("BIM->"+element.bimFileID + "bai->"+element.baiFileID)
        });
       
        let auxBam: File2[] = [];
        const promises = embryos.map(async (ind) => {
            let baiFile: File2 = {
                id: uuidv4(),
                name: '',
                data: '',
                size: 0,
                mimeType: '',
                extension: 'bam.bai',
                ownerID: '',
                embryo: ind,
                pgType: ''
            };
    
            // Obtener el archivo BAI si el ID no está vacío
            if (ind.baiFileID !== '') {
                let auxBaiFile = await firstValueFrom(fileService.getBAIModel(ind.baiFileID));
                if (auxBaiFile) {
                    baiFile = {
                        id: auxBaiFile.id,
                        name: auxBaiFile.name,
                        data: auxBaiFile.data,
                        size: auxBaiFile.size,
                        mimeType: auxBaiFile?.mimeType,
                        extension: auxBaiFile?.extension,
                        ownerID: auxBaiFile?.ownerID,
                        embryo: ind,
                        pgType: ''
                    };
                }
                auxBam.push(baiFile);
            }
            // Obtener el archivo BIM con lógica de reintentos
            let bimFile: File2 = {
                id: uuidv4(),
                name: '',
                data: '',
                size: 0,
                mimeType: '',
                extension: '.bam',
                ownerID: '',
                embryo: ind,
                pgType: ''
            };
    
            if (ind.bimFileID !== '') {
                let auxBimFile = null;
                let attempts = 0;
                
                // Intentar hasta 3 veces si auxBimFile es null
                while (attempts < 4 && !auxBimFile) {
                    try {
                        auxBimFile = await firstValueFrom(fileService.getBIMModel(ind.bimFileID));
                        console.log(`Intento ${attempts + 1} de 4: BIM File ID - ${auxBimFile?.id}`);
                        
                        if (auxBimFile) {
                            bimFile = {
                                id: auxBimFile.id,
                                name: auxBimFile.name,
                                data: auxBimFile.data,
                                size: auxBimFile.size,
                                mimeType: auxBimFile?.mimeType,
                                extension: auxBimFile?.extension,
                                ownerID: auxBimFile?.ownerID,
                                embryo: ind,
                                pgType: ''
                            };
                        }
                    } catch (error) {
                        console.error(`Error al obtener BIM File en el intento ${attempts + 1}:`, error);
                    }
    
                    if (!auxBimFile) {
                        console.log(`Archivo BIM no encontrado, reintentando en 1 segundo...`);
                        await new Promise(resolve => setTimeout(resolve, 5000));
                    }
    
                    attempts++;
                }
    

                if (auxBimFile) {
                    auxBam.push(bimFile);
                } 
            }
    
            if (ind.bimFileID === '' && ind.baiFileID === '') {
                auxBam.push(baiFile);
            }
        });
    
        await Promise.all(promises);
    


        setBamFiles(auxBam.sort((a, b) => a.embryo.embryo- b.embryo.embryo));
    bamFiles.forEach(element => {
        console.log("id files->"+element.id + element.name)
            
        });

        setIsLoadingBamFiles(false);
        setIsLoadingBamFiles2(false);

    };


    const [currentFileEmbryo, setCurrentFileEmbryo] = useState<Embryo>()


    const handleFileInput3 = async (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        const fl = (event.target as HTMLInputElement).files;
        if (!fl?.length) {
            return;
        }

        let bamFile: File | null = null;
        let baiFile: File | null = null;

        let bamID = '';

        // Valida los archivos seleccionados
        for (let i = 0; i < fl.length; i++) {
            const file = fl[i];
            if (file.name.endsWith('.bam')) {
                bamFile = file;
            } else if (file.name.endsWith('.bai')) {
                baiFile = file;
            }
        }

        // Procesar archivo .bam
        if (bamFile) {
            setIsLoadingBamFiles2(true);
            const dataBam = await dataToBase642(bamFile);
            let bamDTO = new F(convertToFile(bamFile, dataBam.base64)).toDTO();
            bamDTO.extension = 'bam';

            // Encuentra el índice del archivo .bam en la lista existente
            let bamFileIndex = bamFiles.findIndex(
                (item) => item.embryo.embryo == currentFileEmbryo?.embryo && item.extension == 'bam'
            );

            if (currentFileEmbryo) {
                let newBamFile: File2 = {
                    id: bamDTO.id,
                    name: bamDTO.name,
                    data: bamDTO.data,
                    size: bamDTO.size,
                    mimeType: bamDTO.mimeType,
                    extension: bamDTO.extension,
                    ownerID: bamDTO.ownerID,
                    embryo: currentFileEmbryo,
                    pgType: ''
                };

                let auxBamFiles = [...bamFiles];
                auxBamFiles[bamFileIndex] = newBamFile;
                setBamFiles(auxBamFiles);

                // Llama al servicio correspondiente
                fileService.addBIM(bamDTO)
                let individualsAux = embryos.filter(
                    (f) => toModel(f).embryo == currentFileEmbryo.embryo
                );

                bamID = bamDTO.id;


                if (individualsAux.length > 0) {
                    let individual = individualsAux[0];
                    individual.bimFileID = bamDTO.id;

                    let updatedIndividuals = embryos.map(i => 
                        i.embryo == individual.embryo ? individual : i
                    );


                    setEmbryos(updatedIndividuals);
           
                   

                    embryoService.updateBamFiles(individual);
                    refreshData()

                }

                setIsLoadingBamFiles2(false);
            }
        }

        // Procesar archivo .bai
        if (baiFile) {
            setIsLoadingBamFiles2(true);
            const dataBai = await dataToBase642(baiFile);
            let baiDTO = new F(convertToFile(baiFile, dataBai.base64)).toDTO();
            baiDTO.extension = 'bai';

            // Encuentra el índice del archivo .bai en la lista existente
            let baiFileIndex = bamFiles.findIndex(
                (item) => item.embryo.embryo == currentFileEmbryo?.embryo && item.extension == 'bai'
            );


            if (currentFileEmbryo) {
                let newBaiFile: File2 = {
                    id: baiDTO.id,
                    name: baiDTO.name,
                    data: baiDTO.data,
                    size: baiDTO.size,
                    mimeType: baiDTO.mimeType,
                    extension: baiDTO.extension,
                    ownerID: baiDTO.ownerID,
                    embryo: currentFileEmbryo || 0,
                    pgType: ''
                };

                let auxBamFiles = [...bamFiles];
                auxBamFiles[baiFileIndex] = newBaiFile;
                setBamFiles(auxBamFiles);

                // Llama al servicio correspondiente
                fileService.addBAI(baiDTO).subscribe(() => {
                    let individualsAux = embryos.filter(
                        (f) => toModel(f).embryo == currentFileEmbryo.embryo
                    );
                    if (individualsAux.length > 0) {
                        let individual = individualsAux[0];
                        if (baiFile) {
                            individual.baiFileID = baiDTO.id;

                            let updatedIndividuals = embryos.map(i => 
                                i.embryo == individual.embryo ? individual : i
                            );
                            

                            setEmbryos(updatedIndividuals);

                            
                            embryoService.updateBamFiles(individual);
                            refreshData()
                        }
                    }
                });

                setIsLoadingBamFiles2(false);
            }

        }
        // Limpiar el input de archivos
        event.target.value = '';
    };

    const changeFileField = (f: File2) => {
        // Llamar al input para seleccionar ambos archivos (bam y bai)
        if (fileInputRefBAMBAI.current) {
            setCurrentFileEmbryo(toModel(f.embryo))
            fileInputRefBAMBAI.current.click();
        }
    }

    const actionsFiles: Actions<File2> = {
        actionsColumn: t('Actions'),
        items: [
            {
                handler: (item: File2) => changeFileField(item),
                icon: uploadIcon,
                label: t('upload'),
            },
        ],
    }


    const fieldsFiles: Field<File2>[] = [
        {
            sortable: false,
            searchable: false,
            label: t('name'),
            name: 'name',
        },
        {
            sortable: false,
            searchable: false,
            label: t('type'),
            name: 'extension',
        },
        {
            sortable: false,
            searchable: false,
            label: t('embryo'),
            name: 'embryo',
            renderFunc: (f, item) => (item.embryo.embryo +1).toString(),
        },
    ]

    const [modalIGVOpened, setModalIGVOpened] = useState<boolean>(false)

    const openModalIGV = () => {
        setModalIGVOpened(true)
    }

    const closeIGVModal = () => {
        setModalIGVOpened(false)
    }
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }
    const snackbarFunction = () => (
        <div>
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            style={{
                position: 'absolute',
                left: '50%',
                marginLeft: 'auto',
            }}>
            <Alert
                onClose={handleClose}
                severity={'error'}
                style={{
                    marginLeft: 'initial',
                }}>
                {err}
            </Alert>
        </Snackbar>
    </div>
    )

    const halfColor = (colorS: string, position: number) => {
        if (colorS === "" ) return '#ddeafb' 
        colorS = colorS?.slice(1, -1);

        if(colorS?.length === 7) {
            return colorS
        } else {
            const color = colorS?.split(",")
            return color[position]
        }
     }

    return (
        <>
        <Typography
                style={{
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: 'rgb(125, 191, 220)',
                    marginBottom: '0.5%',
                }}
                align="left">
                {"PGT-M".toLocaleUpperCase()} - {projectName} 
            </Typography>
            <Divider style={{marginBottom: '2%', width: '100%'}}/>
            <Grid item xs={12} style={{marginBottom: '2.5%'}}>
                <Grid item xs={4} style={{marginBottom: '3%'}}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel shrink={true} id="selectInformativity">
                            {t('selectEmbryo')}
                        </InputLabel>
                        <Select
                            labelId="selectInformativity"
                            id="selectInformativity"
                            fullWidth
                            style={{textAlign: 'left'}}
                            value={project?.embryosGroupID}
                            onChange={(e) => e && handleChangeEmbryo(e.target.value as string)}
                            label={t('language')}>
                            {embryosGroup.filter((e) => e.name).map((l, i) => (
                                <MenuItem value={l.id} key={l.id}>
                                    {l.name || l.id}
                                </MenuItem>
                            ))}

                            <MenuItem value={undefined} key={undefined}>
                                {t('doNotSelect')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {!isLoading && !showSpinner ? (
                               

                    <TableContainer>
                        <Table>
                            <Head
                                fields={fields}
                                styleHeader={{fontWeight: 'bold', color: 'rgb(125, 191, 220)'}}
                                actions={actions}
                            />
                            <Body actions={actions} fields={fields} items={[]} rowKeyField={DEFAULT_ROW_KEY}/>
                            {embryos.map((embryo, index) => (
                                <TableRow>
                                    <TableCell key="name" className={styles.selectCell}>
                                        <TextField
                                            fullWidth
                                            variant={'outlined'}
                                            id={'name'}
                                            type={'string'}
                                            label={t('name')}
                                            onChange={(e) => handleChangeArray('name', e.target.value, index)}
                                            value={embryo.name}
                                            onBlur={() => handleUpdate(index)}
                                        />
                                    </TableCell>
                                    <TableCell key="date" className={styles.selectCell}>
                                        {parseDate(embryo.date)}
                                    </TableCell>
                                    <TableCell key="finalStatus" className={styles.selectCell}>
                                        {finalStatusTitle()[embryo.finalStatus]}
                                    </TableCell>
                                    <TableCell key="embryoGender" className={styles.selectCell}>
                                       {t(embryo.embryoGender)}
                                    </TableCell>
                                    <TableCell key="color">
                                        <div className={styles.row}>

                                            <div className={styles.colorsRow}>
                                                <div className={styles.subColor} style={{backgroundColor: halfColor(embryo.a1Colors, 0)}}></div>
                                                <div className={styles.subColor} style={{backgroundColor: halfColor(embryo.a1Colors, 1)}}></div>
                                            </div>
                                            <div className={styles.colorsRow}>
                                                <div className={styles.subColor} style={{backgroundColor: halfColor(embryo.a2Colors, 0)}}></div>
                                                <div className={styles.subColor} style={{backgroundColor: halfColor(embryo.a2Colors, 1)}}></div>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell key="actions">
                                        <Box display="flex" justifyContent="space-between">
                                            <GenericTooltip
                                                values={[t('upload')]}
                                                icon={
                                                    <img
                                                        src={uploadIcon}
                                                        alt=''
                                                        className={styles.imgAction}
                                                        onClick={() => handleReplace(embryo)}
                                                    />
                                                }
                                                noUseFab={true}
                                            />
                                            <GenericTooltip
                                                values={[t('delete')]}
                                                icon={
                                                    <img
                                                        alt=''
                                                        src={deleteIcon}
                                                        className={styles.imgAction}
                                                        onClick={() => deleteEmbryo(embryo, index)}
                                                    />
                                                }
                                                noUseFab={true}
                                            />
                                            <GenericTooltip
                                                values={[t('download2')]}
                                                icon={
                                                    <img
                                                        src={downloadIcon}
                                                        alt=''
                                                        className={styles.imgAction}
                                                        onClick={() =>
                                                            downloadFile(
                                                                embryo.file?.name || '',
                                                                embryo.file?.mimeType || '',
                                                                embryo.file?.data || ''
                                                            )
                                                        }
                                                    />
                                                }
                                                noUseFab={true}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell key="name" className={styles.selectCell}>
                                    {lastAdded.name}
                                </TableCell>
                                <TableCell key="individatedual" className={styles.selectCell}>
                                    {parseDate(lastAdded.date)}
                                </TableCell>
                                <TableCell key="finalStatus" className={styles.selectCell}>
                                    {finalStatusTitle()[lastAdded.finalStatus]}
                                </TableCell>
                                <TableCell key="embryoGender" className={styles.selectCell}>
                                    {lastAdded.embryoGender}
                                </TableCell>
                                <TableCell key="color" className={styles.selectCell}>
                                    <div className={styles.row}></div>
                                </TableCell>
                                <TableCell key="actions">
                                    <Box display="flex" justifyContent="space-between">
                                        {actions.items.map((a) => {
                                            return (
                                                <GenericTooltip
                                                    values={[t(a.label || a.icon)]}
                                                    icon={
                                                        <img
                                                            src={a.icon}
                                                            alt=''
                                                            className={styles.imgAction}
                                                            onClick={() => a.handler(lastAdded)}
                                                        />
                                                    }
                                                    noUseFab={true}
                                                />
                                            )
                                        })}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box>
                        <CircularProgress/>
                    </Box>
                )}

                {(!isLoadingBamFiles && !isLoadingBamFiles2) ? (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>} // Icono para expandir el acordeón
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography
                                style={{
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    color: 'rgb(125, 191, 220)',
                                    marginBottom: '0.5%',
                                    marginTop: '5%',
                                }}
                                align="left"
                            >
                                {t('bamFiles').toLocaleUpperCase()}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AppTable
                                styleHeader={{color: COLOR_CLINICS}}
                                actions={actionsFiles}
                                fields={fieldsFiles}
                                items={bamFiles}
                                rowKeyField={'id'}
                            />
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <Box>
                        <CircularProgress/>
                    </Box>
                )}


                <input
                    onChange={(event) => handleFileInput(event)}
                    multiple={true}
                    ref={fileInputRef}
                    type="file"
                    hidden
                    accept=".vcf"
                />
                <input
                    onChange={(event) => handleFileInput2(event)}
                    multiple={false}
                    ref={fileInputRef2}
                    type="file"
                    hidden
                    accept=".vcf"
                />
                <input
                    onChange={(event) => handleFileInput3(event)}
                    multiple={true}
                    ref={fileInputRefBAMBAI}
                    type="file"
                    hidden
                    accept=".bam,.bai"
                />

                <Grid item xs={12} className={styles.backButtonGrid} style={{marginTop: '3%'}}>
                    <Button
                        variant="contained"
                        startIcon={<ArrowBack/>}
                        onClick={props.decreaseStep}
                        className={styles2.button}
                        style={{display: 'flex', alignSelf: 'flex-start'}}>
                        {t('back2')}
                    </Button>
                    {project?.activePgd && (
                        <Button
                            variant="contained"
                            onClick={runParentsSnipetsProcess}
                            className={styles2.button}
                            style={{display: 'flex', alignSelf: 'flex-start'}}>
                            {t('runProcess')}
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        onClick={openModalIGV}
                        className={styles2.button}
                        style={{display: 'flex', alignSelf: 'flex-start'}}>
                        {t('igvButton')}
                    </Button>
                    {project?.activePgd && (
                        <Button
                            variant="contained"
                            endIcon={<ArrowForward/>}
                            onClick={openModalSaveGroup}
                            disabled={ isViewData}
                            className={styles2.button}
                            style={{display: 'flex', alignSelf: 'flex-start'}}>
                            {t('continue')}
                        </Button>
                    )}
                </Grid>
            {snackbarFunction()}

            </Grid>
            <Modal open={modalDeleteOpened}>
                <Box className={styles2.modal}>
                    <form onSubmit={deleteConfirmed}>
                        <Box mt={2} textAlign={'justify'}>
                            {t('embryoDeleteConfirmation')}
                        </Box>
                        <FormActions actions={actionsDelete} message={t('confirm')}/>
                    </form>
                </Box>
            </Modal>
            <Modal open={modalReplaceOpened}>
                <Box className={styles2.modal}>
                    <form onSubmit={replaceConfirmed}>
                        <Box mt={2} textAlign={'justify'}>
                            {t('individualReplaceConfirmationMessage')}
                        </Box>
                        <FormActions actions={actionsReplace} message={t('confirm')}/>
                    </form>
                </Box>
            </Modal>

            {dataLoaded ? (
                <ModalPreview
                    data={data}
                    project={props.project}
                    setProject={props.setProject}
                    modalPreviewOpened={modalPreviewOpened}
                    setModalPreviewOpened={setModalPreviewOpened}
                    currentStep={props.currentStep}
                    runParentsSnipetsProcess={runParentsSnipetsProcess}
                />
            ) : (
                <Modal open={!dataLoaded && modalPreviewOpened}>
                    <div className={styles.modalLoading}>
                        <CircularProgress className={styles.circularProgress}/>
                    </div>
                </Modal>
            )}

            <Modal open={modalIGVOpened}>
                <Box className={styles2.modalIGV} style={{ textAlign: 'center' }}>
                    <IGV project={props.project} />
                    <Button
                        variant="contained"
                        onClick={closeIGVModal}
                        className={styles2.button}
                        style={{ marginBottom: '20px' }}
                    >
                        {t('close')}
                    </Button>
                </Box>
            </Modal>

            <Modal open={modalSaveGroupOpened}>
                <Box className={styles2.modal}>
                    <form onSubmit={saveGroup}>
                        <Box mt={2} textAlign={'justify'}>
                            {t('embryoGroupNameModalMessage')}
                        </Box>
                        <Box mt={2} textAlign={'justify'}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                id={'name'}
                                type={'string'}
                                label={t('embryoGroupName')}
                                onChange={(e) => handleNameGroup('name', e.target.value)}
                                value={nameGroup}
                            />
                        </Box>
                        <FormActions actions={actionsDelete} message={t('save')}/>
                    </form>
                </Box>
            </Modal>
        </>
    )

}
