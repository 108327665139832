import { v4 as uuidv4 } from 'uuid'

export interface FileDTO {
  id: string
  name: string
  data: string
  size: number
  mimeType: string
  extension: string
  ownerID: string
  pgType: string 
}

export class File {
  private readonly _id: string
  private readonly _name: string
  private readonly _data: string
  private readonly _size: number
  private readonly _mimeType: string
  private readonly _extension: string
  private readonly _ownerID: string
  private readonly _pgType: string

  constructor(p: FileDTO) {
    this._id = p.id
    this._name = p.name
    this._data = p.data
    this._size = p.size
    this._mimeType = p.mimeType
    this._extension = p.extension
    this._ownerID = p.ownerID
    this._pgType = p.pgType 
  }

  get pgType(): string { return this._pgType
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get data(): string {
    return this._data
  }

  get size(): number {
    return this._size
  }

  get mimeType(): string {
    return this._mimeType
  }

  get extension(): string {
    return this._extension
  }

  get ownerID(): string {
    return this._ownerID
  }

  toDTO(): FileDTO {
    return {
      id: this.id,
      name: this.name,
      data: this.data,
      size: this.size,
      mimeType: this.mimeType,
      extension: this.extension,
      ownerID: this.ownerID,
      pgType: this.pgType,
    }
  }
}

export function toModel(d: FileDTO): File {
  return new File(d)
}

export function toModelRange(d: BedfileRangeDTO): BedfileRange {
  return new BedfileRange(d)
}


export function emptyFileDTO(): FileDTO {
  return {
    id: uuidv4(),
    name: '',
    data: '',
    size: 0,
    mimeType: '',
    extension: '',
    ownerID: '',
    pgType: '',
  }
}

export interface BedfileDTO {
  id: string
  creationDate: Date
  fileName: string
  revisionName: string
  gene: string
  fileID: string
  data: string
  name: string
}

export class Bedfile {
  private _id: string
  private _creationDate: Date
  private _fileName: string
  private _revisionName: string
  private _gene: string
  private _fileID: string
  private _data: string
  private _name: string

  constructor(p: BedfileDTO) {
    this._id = p.id
    this._creationDate = p.creationDate
    this._fileName = p.fileName
    this._revisionName = p.revisionName
    this._gene = p.gene
    this._fileID = p.fileID
    this._data = p.data
    this._name = p.name
  }

  get id(): string {
    return this._id
  }

  set id(id: string) {
    this._id = id
  }

  get name(): string {
    return this._name
  }

  set name(name: string) {
    this._name = name
  }

  get creationDate(): Date {
    return this._creationDate
  }

  set creationDate(creationDate: Date) {
    this._creationDate = creationDate
  }

  get fileName(): string {
    return this._fileName
  }

  set fileName(fileName: string) {
    this._fileName = fileName
  }

  get revisionName(): string {
    return this._revisionName
  }

  set revisionName(revisionName: string) {
    this._revisionName = revisionName
  }

  get gene(): string {
    return this._gene
  }

  set gene(gene: string) {
    this._gene = gene
  }

  get fileID(): string {
    return this._fileID
  }

  set fileID(fileID: string) {
    this._fileID = fileID
  }

  get data(): string {
    return this._data
  }

  set data(data: string) {
    this._data = data
  }

  toDTO(): BedfileDTO {
    return {
      id: this.id,
      creationDate: this.creationDate,
      fileName: this.fileName,
      revisionName: this.revisionName,
      gene: this.gene,
      fileID: this.fileID,
      data: this.data,
      name: this.name,
    }
  }
}

export interface BedfileRangeDTO {
  startSNP: number
  endSNP: number
  chromosome: string
}

export class BedfileRange {
  private _startSNP: number

  private _endSNP: number

  private _chromosome: string

 

  constructor(br: BedfileRangeDTO) {
    this._startSNP = br.startSNP
    this._endSNP = br.endSNP
    this._chromosome = br.chromosome

  }

  public get startSNP(): number {
    return this._startSNP
  }

  public get endSNP(): number {
    return this._endSNP
  }

  public get chromosome(): string {
    return this._chromosome
  }

  toDTO(): BedfileRangeDTO {
    return {
      startSNP: this.startSNP,
      endSNP: this.endSNP,
      chromosome: this.chromosome
    }
}

}
export function toModelBedfile(d: BedfileDTO): Bedfile {
  return new Bedfile(d)
}

export interface BedfileQuery {
}

export interface PanelDTO {
  id: string
  gene: string
  geneName: string
  herencyPatern: string
  pathology: string
  genRegion:     string                 
	transcrit:     string                 
	chr:           string                 
	start:         number                 
	end:           number               
	strand:        boolean                   
	synonyms:      string                 
}

export class Panel {
  private _id: string
  private _gene: string
  private _geneName: string
  private _herencyPatern: string
  private _pathology: string
  private _genRegion: string
  private _transcrit: string
  private _chr: string                 
	private _start: number                 
	private _end: number               
	private _strand: boolean                   
	private _synonyms: string       

  constructor(p: PanelDTO) {
    this._id = p.id
    this._gene = p.gene
    this._geneName = p.geneName
    this._herencyPatern = p.herencyPatern
    this._pathology = p.pathology
    this._genRegion = p.genRegion
    this._transcrit = p.transcrit
    this._chr = p.chr
    this._start = p.start
    this._end = p.end
    this._strand = p.strand
    this._synonyms = p.synonyms
  }

  get id(): string {
    return this._id
  }

  get gene(): string {
    return this._gene
  }

  get geneName(): string {
    return this._geneName
  }

  get pathology(): string {
    return this._pathology
  }

  get herencyPatern(): string {
    return this._herencyPatern
  }

  get genRegion(): string {
    return this._genRegion
  }

  get transcrit(): string {
    return this._transcrit
  }

  get chr(): string {
    return this._chr
  }

  get start(): number {
    return this._start
  }

  get end(): number {
    return this._end
  }

  get strand(): boolean {
    return this._strand
  }

  get synonyms(): string {
    return this._synonyms
  }

  toDTO(): PanelDTO {
    return {
      id: this.id,
      gene: this.gene,
      geneName: this.geneName,
      herencyPatern: this.herencyPatern,
      pathology: this.pathology,
      genRegion: this.genRegion,                 
      transcrit: this.transcrit,        
      chr: this.chr,        
      start: this.start,              
      end: this.end,          
      strand: this.strand,                 
      synonyms: this.synonyms
    }
  }
}

export function toModelPanel(d: PanelDTO): Panel {
  return new Panel(d)
}

export interface PanelQuery {
}

export interface PanelFrontDTO {
    id: string
    gene: string
    geneName: string
    herencyPatern: string[]
    pathology: string
    genRegion:     string                 
    transcrit:     string                 
    chr:           string                 
    start:         number                 
    end:           number               
    strand:        boolean                   
    synonyms:      string 
}

export class PanelFront {
    private _id: string
    private _gene: string
    private _geneName: string
    private _herencyPatern: string[]
    private _pathology: string
    private _genRegion: string
    private _transcrit: string
    private _chr: string                 
    private _start: number                 
    private _end: number               
    private _strand: boolean                   
    private _synonyms: string  
    

    constructor(p: PanelFrontDTO) {
        this._id = p.id
        this._gene = p.gene
        this._geneName = p.geneName
        this._herencyPatern = p.herencyPatern
        this._pathology = p.pathology
        this._genRegion = p.genRegion
        this._transcrit = p.transcrit
        this._chr = p.chr
        this._start = p.start
        this._end = p.end
        this._strand = p.strand
        this._synonyms = p.synonyms
    }

    get id(): string {
        return this._id
    }

    get gene(): string {
        return this._gene
    }

    get geneName(): string {
        return this._geneName
    }

    get pathology(): string {
        return this._pathology
    }

    get herencyPatern(): string[] {
        return this._herencyPatern
    }

    get genRegion(): string {
      return this._genRegion
    }
  
    get transcrit(): string {
      return this._transcrit
    }
  
    get chr(): string {
      return this._chr
    }
  
    get start(): number {
      return this._start
    }
  
    get end(): number {
      return this._end
    }
  
    get strand(): boolean {
      return this._strand
    }
  
    get synonyms(): string {
      return this._synonyms
    }

    toDTO(): PanelFrontDTO {
        return {
            id: this.id,
            gene: this.gene,
            geneName: this.geneName,
            herencyPatern: this.herencyPatern,
            pathology: this.pathology,
            genRegion: this.genRegion,                 
            transcrit: this.transcrit,        
            chr: this.chr,        
            start: this.start,              
            end: this.end,          
            strand: this.strand,                 
            synonyms: this.synonyms
            
        }
    }
}

export function toModelPanelFront(d: PanelFrontDTO): PanelFront {
    return new PanelFront(d)
}
export interface FilePgDTO {
  id: string
  name: string
  data: string
  size: number
  mimeType: string
  extension: string
  ownerID: string
  pgType: string
}

export class FilePg {
  private readonly _id: string
  private readonly _name: string
  private readonly _data: string
  private readonly _size: number
  private readonly _mimeType: string
  private readonly _extension: string
  private readonly _ownerID: string
  private readonly _pgType: string

  constructor(p: FilePgDTO) {
    this._id = p.id
    this._name = p.name
    this._data = p.data
    this._size = p.size
    this._mimeType = p.mimeType
    this._extension = p.extension
    this._ownerID = p.ownerID
    this._pgType = p.pgType
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get data(): string {
    return this._data
  }

  get size(): number {
    return this._size
  }

  get mimeType(): string {
    return this._mimeType
  }

  get extension(): string {
    return this._extension
  }

  get ownerID(): string {
    return this._ownerID
  }
  get pgType(): string {
    return this._pgType
  }

  toDTOPg(): FilePgDTO {
    return {
      id: this.id,
      name: this.name,
      data: this.data,
      size: this.size,
      mimeType: this.mimeType,
      extension: this.extension,
      ownerID: this.ownerID,
      pgType: this.pgType,
    }
  }
}

export function toModelPg(d: FilePgDTO): FilePg {
  return new FilePg(d)
}

export function emptyFilePgDTO(): FilePgDTO {
  return {
    id: uuidv4(),
    name: '',
    data: '',
    size: 0,
    mimeType: '',
    extension: '',
    ownerID: '',
    pgType: '',
  }
}

export interface BedfileQuery {
 pgd: boolean
 pgsec: boolean
}